import React from 'react'
import { withRouter } from 'react-router-dom'
import classnames from 'classnames'

// styles
import useStyles from './styles'

// components
import Header from '../Header'
import Sidebar from '../Sidebar'

// context
import { useLayoutState } from '../../context/LayoutContext'

function Layout(props) {
  var classes = useStyles()

  // global
  var layoutState = useLayoutState()

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened
          })}
        >
          <div className={classes.fakeToolbar} />
          {props.children}
        </div>
      </>
    </div>
  )
}

export default withRouter(Layout)
