import React from "react";
import { Grid } from "@material-ui/core";


// components
import Widget from "../../components/Widget/Widget";
import ApexSemiGaugeChart from "./components/ApexSemiGaugeChart";
import ApexHeatmap from "./components/ApexHeatmap";
import ApexBarChart from "./components/ApexBarChart";
import PageTitle from "../../components/PageTitle/PageTitle";


export default function Charts(props) {

  return (
    <>
      <PageTitle title="APIs Monitoring" button="Latest Reports" />
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Widget title="Average API availability" upperTitle noBodyPadding>
            <ApexSemiGaugeChart />
          </Widget>
        </Grid>
        <Grid item xs={12} md={6}>
          <Widget title="API calls (per hour of the day)" upperTitle noBodyPadding>
            <ApexHeatmap />
          </Widget>
        </Grid>
        
       

        <Grid item xs={12} md={6}>
          <Widget title="Average API availability" upperTitle noBodyPadding>
            <ApexBarChart />
          </Widget>
        </Grid>

      </Grid>
    </>
  );
}

// ################################################################

