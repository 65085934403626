import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    position: "absolute",
    bottom: theme.spacing(1),
    height: "auto",
  },
  edit: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  delete: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(6),
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '80%',
    maxHeight: '80%',
  },
}));
