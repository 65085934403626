import React from 'react'
import { ThemeProvider } from 'styled-components'
import { themes } from 'styles/theme'
import { ThemeToggleContext } from 'context/themeContext'
import GlobalStyles from 'styles/GlobalStyles'

export default function GlobalLayout({ children }) {
  const [themeState, setThemeState] = React.useState({
    mode: 'light'
  })

  const toggle = () => {
    const mode = themeState.mode === 'light' ? `dark` : `light`
    setThemeState({ mode: mode })
  }

  return (
    <ThemeToggleContext.Provider value={{ toggle: toggle }}>
      <ThemeProvider
        theme={{
          ...themes,
          mode: themeState.mode
        }}
      >
        <>
          <GlobalStyles />
          {children}
        </>
      </ThemeProvider>
    </ThemeToggleContext.Provider>
  )
}
