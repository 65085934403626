import React from "react";

// styles
import "react-toastify/dist/ReactToastify.css";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import { Typography } from "../../components/Wrappers/Wrappers";

export default function SupportPage(props) {

  return (
    <>
      <PageTitle title="Support" />
      <Typography size="md" weight="medium" color="warning">Les solutions de support sont en cours d'études...</Typography>
    </>
  );
}
