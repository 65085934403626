import React, { useState, useEffect } from 'react'
import { Section } from 'components/global'
import Loader from 'react-loader-spinner'
import axios from 'axios'
import { Grid } from "@material-ui/core";

// translate
import Translate from 'locales/Translate'

// styles
import useStyles from "./styles";

// components
import { Typography } from "../../components/Wrappers/Wrappers";
import Widget from "../../components/Widget/Widget";
import ApiLayout from "./components/ApiLayout/ApiLayout";
import PackageLayout from "./components/PackageLayout/PackageLayout";
import BankingLayout from "./components/BankingLayout/BankingLayout";
import ApplicationLayout from "./components/ApplicationLayout/ApplicationLayout";

// images
import Fosfo from "../../images/fosfo.png";
import Max1 from "../../images/carteMAX1.png";
import CMB from "../../images/cmb.png";
import MAX from "../../images/Max.png";
import Fortuneo from "../../images/fortuneo.png";

export default function ProductsPage(props) {
  var classes = useStyles();

  const [products, setProducts] = useState([])
  useEffect(() => {
    fetchProducts().then(response => {
      let products = response.data
      // On transforme descriptions, on remet les language en index de tableau
      products = products.map(product => {
        const descriptionsByLanguage = product.descriptions.reduce(
          (memo, { language, ...message }) => {
            memo[language] = message
            return memo
          },
          {}
        )
        return {
          ...product,
          descriptions: descriptionsByLanguage
        }
      })
      setProducts(products)
    })
  }, [])

  if (products && !products.length) {
    return (
      <Section id="catalogue">
        <div id="loader">
          <Loader type="Triangle" color="#FF0000" height="100" width="100" />
        </div>
      </Section>
    )
  }

  async function fetchProducts() {
    return axios.get(process.env.REACT_APP_API_PRODUCTS)
  }

  return (
    <>
    <Typography className={classes.typo} variant="h1" size="sm"><Translate string={'devportal.products.title'} /></Typography><br/>
      <Grid container spacing={3}>
        <Grid item xs={12} >
          <Widget title="APIs">
            <Grid container spacing={3}>
              {products.map((product, index) => {
                return <ApiLayout product={product} key={index} rating="4"/>
              })}
            </Grid>
          </Widget>
        </Grid>
        <Grid item xs={12} >
          <Widget title="Packages">
            <Grid container spacing={3}>
              <PackageLayout title="Accounts" channel="B2C" description="Ce package permet d'accéder et de gérer les comptes d'une personne." rating="4"/>
            </Grid>
          </Widget>
        </Grid>
        <Grid item xs={12} >
          <Widget title="Banking Products">
            <Grid container spacing={3}>
              <BankingLayout image={Fosfo} title="Carte FOSFO" bank="Fortuneo" description="Fortuneo lance Fosfo : sa nouvelle carte gratuite et sans frais‎." rating="5"/>
              <BankingLayout image={Max1} title="Carte MAX" bank="Max" description="La Carte max : une carte bancaire gratuite, qui réunit toutes vos cartes en une !" rating="5"/>
            </Grid>
          </Widget>
        </Grid>
        <Grid item xs={12} >
          <Widget title="Applications">
           <Grid container spacing={3}>
              <ApplicationLayout image={CMB} title="CMB ma banque : solde, virement & épargne" company="Crédit Mutuel Arkea" description="Suivi de mes comptes et opérations disponibles depuis mon mobile." rating="3.5"/>
              <ApplicationLayout image={MAX} title="MAX" company="Nouvelle Vague" description="Bonjour c’est max, votre assistant personnel 100% mobile et 100% gratuit !" rating="4"/>
              <ApplicationLayout image={Fortuneo} title="Fortuneo, banque & bourse en ligne" company="Fortuneo" description="Accompagnement mobile personnalisé dans la gestion de vos comptes." rating="4.5"/>
            </Grid>
          </Widget>
        </Grid>
        <Grid item xs={12} >
          <Widget title="By-Products"></Widget>
        </Grid>
      </Grid>
    </>
  );  
}