import React from 'react'
import styled from 'styled-components'
import Translate from 'locales/Translate'
import CustomButton from 'common/CustomButton/CustomButton'

import img from 'static/images/door.jpg'

export default function Hero() {
  return (
    <SectionStyled>
      <div className="hero-title-wrap">
        <h1>
          <Translate string={'header.title'} />
        </h1>
        <span className="hero-su    btitle">
          <Translate string={'header.title1'} />
          <Translate string={'header.title2'} />
        </span>
      </div>

      <CustomButton to="/docs" className="hero-btn">
        <Translate string={'header.gotoproducts'} />
      </CustomButton>
    </SectionStyled>
  )
}

const SectionStyled = styled.section`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  background-image: url(${img});
  background-size: 100% 100%;

  min-height: 600px;

  @media (max-width: ${props => props.theme.screen.md}) {
    min-height: 300px;
  }

  .hero-subtitle {
    font-size: 24px;
  }

  .hero-btn {
    position: absolute;
    bottom: 100px;

    @media (max-width: ${props => props.theme.screen.md}) {
      bottom: 10px;
    }

    @media (max-width: ${props => props.theme.screen.sm}) {
      bottom: 0;
      font-size: 10px;
    }
  }
`
