import React from 'react'
import Hero from 'sections/Hero'

export default function SectionWrapper() {
  return (
    <div id="home">
      <Hero />
    </div>
  )
}
