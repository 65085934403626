import React from 'react'
import './LanguagePicker.css'
import { LanguageContext } from 'context/languageContext'
import styled from 'styled-components'
import Constants from 'utils/constants'

export default function LanguagePicker(props) {
  const { changeLanguage } = props

  return (
    <LanguageContext.Consumer>
      {value => (
        <StyledLanguagePicker id="languagePicker">
          <p
            id="fr"
            className={value === Constants.LANGUAGE_FR ? 'active' : ''}
            onClick={() => changeLanguage(Constants.LANGUAGE_FR)}
          >
            
            FR
          </p>
          /
          <p
            id="en"
            className={value === Constants.LANGUAGE_EN ? 'active' : ''}
            onClick={() => changeLanguage(Constants.LANGUAGE_EN)}
          >
            EN
          </p>
        </StyledLanguagePicker>
      )}
    </LanguageContext.Consumer>
  )
}

const StyledLanguagePicker = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: ${props => props.theme.color.primary};
  width: 150px;
  height: 100%;
  max-height: 100%;
  top: 0;
  right: 0;
  position: absolute;
`
