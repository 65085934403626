import React from "react";
import { Grid } from "@material-ui/core";

import Translate from 'locales/Translate'

// styles
import useStyles from "./styles";

// components
import { Typography } from "../../components/Wrappers/Wrappers";
import Widget from "../../components/Widget/Widget";
import ApiLayout from "./components/ApiLayout/ApiLayout";
import PackageLayout from "./components/PackageLayout/PackageLayout";
import BankingLayout from "./components/BankingLayout/BankingLayout";
// import ApplicationLayout from "./components/ApplicationLayout/ApplicationLayout";

// images
import Fosfo from "../../images/fosfo.png";
// import Max1 from "../../images/carteMAX1.png";
// import CMB from "../../images/cmb.png";
// import MAX from "../../images/Max.png";
// import Fortuneo from "../../images/fortuneo.png";

export default function MyProductsPage(props) {
  var classes = useStyles();

  return (
    <>
    <Typography className={classes.typo} variant="h1" size="sm"><Translate string={'devportal.myproducts.title'} /></Typography><br/>
      <Grid container spacing={3}>
        <Grid item xs={12} >
          <Widget title="APIs">
            <Grid container spacing={3}>
              <ApiLayout title={'devportal.products.apiimputation.title'} channel="B2C" description={'devportal.products.apiimputation.description'} rating="5"/>
              <ApiLayout title={'devportal.products.apitransfer.title'} channel="B2C" description={'devportal.products.apitransfer.description'} rating="4.5"/>
            </Grid>
          </Widget>
        </Grid>
        <Grid item xs={12} >
          <Widget title="Packages">
            <Grid container spacing={3}>
              <PackageLayout title={'devportal.products.packageaccount.title'} channel="B2C" description={'devportal.products.packageaccount.description'} rating="4"/>
            </Grid>
          </Widget>
        </Grid>
        <Grid item xs={12} >
          <Widget title="Banking Products">
            <Grid container spacing={3}>
              <BankingLayout image={Fosfo} title="Carte FOSFO" bank="Fortuneo" description="Fortuneo lance Fosfo : sa nouvelle carte gratuite et sans frais‎." rating="5"/>
            </Grid>
          </Widget>
        </Grid>
      </Grid>
    </>
  );  
}