import React, { useState, useEffect } from 'react'
import { Drawer, IconButton, List } from '@material-ui/core'
import {
  Dashboard as DashboardIcon,
  AccountBox as SandBoxIcon,
  Power as PowerIcon,
  Apps as AppsIcon,
  FilterNone as UIElementsIcon,
  Build as BuildIcon,
  EuroSymbol as EuroIcon,
  Store as StoreIcon,
  QuestionAnswer as SupportIcon,
  LibraryBooks as LibraryIcon,
  HelpOutline as FAQIcon,
  ArrowBack as ArrowBackIcon
} from '@material-ui/icons'
import { useTheme } from '@material-ui/styles'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'

// styles
import useStyles from './styles'

// components
import SidebarLink from './components/SidebarLink/SidebarLink'

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar
} from '../../context/LayoutContext'

const structure = [
  // Global
  { id: 0, type: 'title', label: 'GLOBAL' },
  { id: 0, label: 'Dashboard', link: '/devportal/dashboard', icon: <DashboardIcon /> },
  { id: 0, label: 'Products', link: '', icon: <StoreIcon />, 
    children: [
      { label: 'Store', link: '/devportal/products' },
      { label: 'My Products', link: '/devportal/myproducts' }
    ]
  },
  { id: 0, label: 'Pricing', link: '/devportal/pricing', icon: <EuroIcon /> },
  // Technical
  { id: 0, type: 'divider' },
  { id: 0, type: 'title', label: 'TECHNICAL' },
  { id: 0, label: 'Documentation', link: '/devportal/documentation', icon: <LibraryIcon/> },
  { id: 0, label: 'APIs', link: '/devportal/apis', icon: <PowerIcon />,
    children: [
      { label: 'Monitoring', link: '/devportal/apis' },
      { label: 'Logs', link: '/devportal/logs' },
      { label: 'System Health', link: '/devportal/systemstatus' }
    ]
  },
  { id: 0, label: 'SDK', link: '/devportal/sdk', icon: <BuildIcon /> },

  // Projects
  { id: 0, type: 'divider' },
  { id: 0, type: 'title', label: 'PROJECTS' },
  { id: 0, label: 'My apps', link: '/devportal/myapps', icon: <AppsIcon /> },
  { id: 0, label: 'SandBox', link: '/devportal/sandbox', icon: <SandBoxIcon />},
  {
    id: 0, label: 'UI Elements', link: '/devportal/ui', icon: <UIElementsIcon />,
    children: [
      { label: 'Icons', link: '/devportal/icons' },
      { label: 'Charts', link: '/devportal/charts' },
      { label: 'Maps', link: '/devportal/maps' }
    ]
  },
  // Help
  { id: 0, type: 'divider' },
  { id: 0, type: 'title', label: 'HELP' },
  { id: 0, label: 'Support', link: '/devportal/support', icon: <SupportIcon /> },
]
// auto increment id
structure.forEach(function(jsonElement) {
  jsonElement.id = structure.indexOf(jsonElement)
})

function Sidebar({ location }) {
  var classes = useStyles()
  var theme = useTheme()

  // global
  var { isSidebarOpened } = useLayoutState()
  var layoutDispatch = useLayoutDispatch()

  // local
  var [isPermanent, setPermanent] = useState(true)

  useEffect(function() {
    window.addEventListener('resize', handleWindowWidthChange)
    handleWindowWidthChange()
    return function cleanup() {
      window.removeEventListener('resize', handleWindowWidthChange)
    }
  })

  return (
    <Drawer
      variant={isPermanent ? 'permanent' : 'temporary'}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened
        })
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse)
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  )

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth
    var breakpointWidth = theme.breakpoints.values.md
    var isSmallScreen = windowWidth < breakpointWidth

    if (isSmallScreen && isPermanent) {
      setPermanent(false)
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true)
    }
  }
}

export default withRouter(Sidebar)
