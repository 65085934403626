import React, { useState } from 'react'
import GlobalLayout from 'common/globalLayout'
import NavbarShowcase from 'showcaseSite/navbar'
import FooterShowcase from 'showcaseSite/footer'
import TermsOfUse from 'components/common/termsOfUse/termsOfUse'
import Privacy from 'components/common/privacy/privacy'
import RedocWrapper from 'common/RedocWrapper'

import SectionsWrapper from 'showcaseSite/sectionsWrapper'

import Catalogue from 'components/showcaseSite/catalogue/catalogue'
import TechnicalDoc from 'components/showcaseSite/technicalDoc/technicalDoc'

import PageNotFound from 'common/PageNotFound/PageNotFound'

import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Redirect } from 'react-router'
import { LanguageContext } from 'context/languageContext'

import AccountsSwaggerRedocDsp2 from 'sections/redocDsp2/AccountsSwaggerRedocDsp2'
import BenificiariesSwaggerRedocDsp2 from 'sections/redocDsp2/BenificiariesSwaggerRedocDsp2'
import ConsentSwaggerRedocDsp2 from 'sections/redocDsp2/ConsentSwaggerRedocDsp2'
import CoverageSwaggerRedocDsp2 from 'sections/redocDsp2/CoverageSwaggerRedocDsp2'
import PaymentInitiationSwaggerRedocDsp2 from 'sections/redocDsp2/PaymentInitiationSwaggerRedocDsp2'
import UserIdentitySwaggerRedocDsp2 from 'sections/redocDsp2/UserIdentitySwaggerRedocDsp2'

//Partie devPortal
import Layout from 'devPortal/components/Layout'
import Dashboard from 'devPortal/pages/dashboard'
import Typography from 'devPortal/pages/typography'
import Notifications from 'devPortal/pages/notifications'
import Maps from 'devPortal/pages/maps'
import Tables from 'devPortal/pages/tables'
import Icons from 'devPortal/pages/icons'
import Charts from 'devPortal/pages/charts'
import Apis from 'devPortal/pages/apis'
import Logs from 'devPortal/pages/logs'
import Products from 'devPortal/pages/products/Products'
import MyProducts from 'devPortal/pages/myproducts/MyProducts'
import Pricing from 'devPortal/pages/pricing/Pricing'
import Systemstatus from 'devPortal/pages/systemstatus/Systemstatus'


import MyApps from 'devPortal/pages/myapps/MyApps'
import Sandbox from 'devPortal/pages/sandbox/Sandbox'
import Support from 'devPortal/pages/support/Support'

import Error from 'devPortal/pages/error'
import { LayoutProvider } from 'devPortal/context/LayoutContext'
import { UserProvider } from 'devPortal/context/UserContext'
import { ThemeProvider } from '@material-ui/styles'
import { CssBaseline } from '@material-ui/core'
import Themes from 'devPortal/themes'

import ScrollToTop from 'utils/ScrollToTop'
import shortid from 'shortid'
import styled from 'styled-components'

import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Constants  from 'utils/constants'

import './App.css'

/*Exemple of route
{
  path: "/auth", //The path
  component: Auth, //The component associated
  layoutType: 'showcase', //If you don't want any layout, don't put layoutType or leave it at a unkown value
  privateRoute: false, //If the user need to be authed in order to access this route, if he is not authed and try to access a private route, he will be redirectied on /auth 
  exact: true // React-router property in order to know if we want the exact route to match, useful for sub-route for example
}
*/

const routesShowcaseSite = [


  {
    path: '/docs',
    component: Catalogue,
    layout: true,
    layoutType: 'showcase',
    privateRoute: false,
    exact: true
  },

  {
    path: '/termsofuse',
    component: TermsOfUse,
    layout: true,
    layoutType: 'showcase',
    privateRoute: false,
    exact: true
  },
  {
    path: '/privacy',
    component: Privacy,
    layout: true,
    layoutType: 'showcase',
    privateRoute: false,
    exact: true
  },


  {
    path: '/technicaldoc',
    component: TechnicalDoc,
    layoutType: 'showcase',
    privateRoute: false,
    exact: true
  },

  {
    path: '/accountsswaggerdsp2',
    component: AccountsSwaggerRedocDsp2,
    layout: true,
    layoutType: 'showcase',
    privateRoute: false,
    exact: true
  },
  {
    path: '/benificiariesswaggerdsp2',
    component: BenificiariesSwaggerRedocDsp2,
    layout: true,
    layoutType: 'showcase',
    privateRoute: false,
    exact: true
  },
  {
    path: '/consentswaggerdsp2',
    component: ConsentSwaggerRedocDsp2,
    layout: true,
    layoutType: 'showcase',
    privateRoute: false,
    exact: true
  },
  {
    path: '/coverageswaggerdsp2',
    component: CoverageSwaggerRedocDsp2,
    layout: true,
    layoutType: 'showcase',
    privateRoute: false,
    exact: true
  },
  {
    path: '/paymentinitiationswaggerdsp2',
    component: PaymentInitiationSwaggerRedocDsp2,
    layout: true,
    layoutType: 'showcase',
    privateRoute: false,
    exact: true
  },
  {
    path: '/useridentityswaggerdsp2',
    component: UserIdentitySwaggerRedocDsp2,
    layout: true,
    layoutType: 'showcase',
    privateRoute: false,
    exact: true
  }
]

const routesDevPortal = [
  {
    path: '/devportal/home',
    component: Dashboard,
    layout: true,
    layoutType: 'devPortal',
    privateRoute: true,
    exact: true
  },
  {
    path: '/devportal/dashboard',
    component: Dashboard,
    layout: true,
    layoutType: 'devPortal',
    privateRoute: true,
    exact: true
  },
  {
    path: '/devportal/typography',
    component: Typography,
    layout: true,
    layoutType: 'devPortal',
    privateRoute: true,
    exact: true
  },
  {
    path: '/devportal/tables',
    component: Tables,
    layout: true,
    layoutType: 'devPortal',
    privateRoute: true,
    exact: true
  },
  {
    path: '/devportal/notifications',
    component: Notifications,
    layout: true,
    layoutType: 'devPortal',
    privateRoute: true,
    exact: true
  },
  {
    path: '/devportal/apis',
    component: Apis,
    layout: true,
    layoutType: 'devPortal',
    privateRoute: true,
    exact: true
  },
  {
    path: '/devportal/dashboard',
    component: Dashboard,
    layout: true,
    layoutType: 'devPortal',
    privateRoute: true,
    exact: true
  },
  {
    path: '/devportal/logs',
    component: Logs,
    layout: true,
    layoutType: 'devPortal',
    privateRoute: true,
    exact: true
  },
  {
    path: '/devportal/products',
    component: Products,
    layout: true,
    layoutType: 'devPortal',
    privateRoute: true,
    exact: true
  },
  {
    path: '/devportal/myproducts',
    component: MyProducts,
    layout: true,
    layoutType: 'devPortal',
    privateRoute: true,
    exact: true
  },
  {
    path: '/devportal/pricing',
    component: Pricing,
    layout: true,
    layoutType: 'devPortal',
    privateRoute: true,
    exact: true
  },
  {
    path: '/devportal/myapps',
    component: MyApps,
    layout: true,
    layoutType: 'devPortal',
    privateRoute: true,
    exact: true
  },
  {
    path: '/devportal/sandbox',
    component: Sandbox,
    layout: true,
    layoutType: 'devPortal',
    privateRoute: true,
    exact: true
  },
  {
    path: '/devportal/support',
    component: Support,
    layout: true,
    layoutType: 'devPortal',
    privateRoute: true,
    exact: true
  },
  {
    path: '/devportal/systemstatus',
    component: Systemstatus,
    layout: true,
    layoutType: 'devPortal',
    privateRoute: true,
    exact: true
  },

  {
    path: '/devportal/ui',
    component: Icons,
    layout: true,
    layoutType: 'devPortal',
    privateRoute: true,
    exact: true
  },
  {
    path: '/devportal/icons',
    component: Icons,
    layout: true,
    layoutType: 'devPortal',
    privateRoute: true,
    exact: true
  },
  {
    path: '/devportal/maps',
    component: Maps,
    layout: true,
    layoutType: 'devPortal',
    privateRoute: true,
    exact: true
  },
  {
    path: '/devportal/charts',
    component: Charts,
    layout: true,
    layoutType: 'devPortal',
    privateRoute: true,
    exact: true
  }
]

const importantRoutes = [
  {
    path: '/showcase',
    component: SectionsWrapper,
    layout: true,
    layoutType: 'showcase',
    privateRoute: false,
    exact: true
  }
]

const routesSwagger = [
  {
    path: '/swagger/:productId',
    component: RedocWrapper,
    layout: true,
    layoutType: 'showcase',
    privateRoute: false,
    exact: true
  }
]

const defaultRoutes = [
  {
    path: '/devportal/*',
    component: Error,
    layout: true,
    layoutType: 'devPortal',
    privateRoute: true,
    exact: false
  },
  {
    path: '*',
    component: PageNotFound,
    layout: true,
    layoutType: 'showcase',
    privateRoute: false,
    exact: true
  }
]

export const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;

export default function App() {
  const { data } = useQuery(IS_LOGGED_IN);
  const [preferredLocale, setPreferredLocale] = useState(Constants.LANGUAGE_FR)
  const allRoutes = [
    ...routesSwagger,
    ...routesDevPortal,
    ...routesShowcaseSite,
    ...importantRoutes,
    ...defaultRoutes
  ]

  function changeLanguage(lang) {
    setPreferredLocale(lang)
  }

  function wrapArray(array) {
    return Array.isArray(array) ? array : [array]
  }

  //Return a layout ( header + menu + footer) wrapped around a globalLayout
  function GlobalLayoutContainer(props) {
    const layoutType = props.layoutType
    const ComponentArr = wrapArray(props.componentArr)
    return (
      <GlobalLayout>
        <GetLayoutFromType layoutType={layoutType}>
          {ComponentArr.map(Component => (
            <Component {...props} key={shortid.generate()} />
          ))}
        </GetLayoutFromType>
      </GlobalLayout>
    )
  }

  //Return a layout ( header + menu + footer) if setted or return no layout if value is no known or no value is setted
  function GetLayoutFromType(props) {
    switch (props.layoutType) {
      case 'showcase':
        return (
          <>
            <NavbarShowcase />
            <Main>{props.children}</Main>
            <FooterShowcase changeLanguage={changeLanguage} />
          </>
        )
      case 'devPortal':
        return (
          <>
            <LayoutProvider>
              <UserProvider>
                <ThemeProvider theme={Themes.default}>
                  <CssBaseline />
                  <Layout>{props.children}</Layout>
                </ThemeProvider>
              </UserProvider>
            </LayoutProvider>
          </>
        )
      default:
        return <>{props.children}</>
    }
  }

  //Attention : L'ordre des routes ont un sens !
  return (
    <>
      <BrowserRouter>
        <ScrollToTop>
          <LanguageContext.Provider value={preferredLocale}>
            <Route exact path="/" render={() => <Redirect to="/showcase" />} />
            <Switch>
              {allRoutes.map((route, index) => (
                <Route
                  path={route.path}
                  key={route.path}
                  render={props =>
                    route.privateRoute && (!data.isLoggedIn)
                      ?
                      (
                        <Redirect
                          to={{
                            pathname: '/auth',
                            state: { from: props.location }
                          }}
                        />
                      ) 
                    
                    :
                    (
                      <GlobalLayoutContainer
                        componentArr={route.component}
                        layoutType={route.layoutType}
                        {...props}
                      />
                    )  
                  }
                />
              ))}
              <Route component={PageNotFound} />
            </Switch>
          </LanguageContext.Provider>
        </ScrollToTop>
      </BrowserRouter>
    </>
  )
}

const Main = styled.main`
  top: 90px;
  position: relative;
  min-height: calc(100vh - 140px);
`
