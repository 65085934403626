import React from 'react'
import styled from 'styled-components'
import Translate from 'locales/Translate'
import './footer.css'

import { HashLink as Link } from 'react-router-hash-link'
import LanguagePicker from 'common/LanguagePicker/LanguagePicker'

// import ToggleSwitch from 'common/toggleSwitch/toggleSwitch'
// import { useTheme } from 'context/themeContext'

export default function Footer(props) {
  // const themeToggle = useTheme()

  return (
    <FooterWrapper className="footer" {...props}>
      <Copyright>
        <FooterDevportalSpan>
          <Link to="/privacy">
            <Translate string={'footer.privacy'} />
          </Link>
        </FooterDevportalSpan>
        <FooterDevportalSpan>
          <Link to="/termsofuse">
            <Translate string={'footer.termsofuse'} />
          </Link>
        </FooterDevportalSpan>
        <FooterDevportalSpan>
          <a href="mailto:openbanking@arkea.com">
            <Translate string={'footer.contact'} />
          </a>
        </FooterDevportalSpan>
      </Copyright>
      <RedSquare className="redSquare">
        <LanguagePicker changeLanguage={props.changeLanguage} />
      </RedSquare>
    </FooterWrapper>
  )
}

const FooterWrapper = styled.footer`
  background-color: ${props => props.theme.color.white.dark};
  position: absolute;
  width: 100%;
  height: 60px;
  bottom: -140px;
  left: 0;
  right: 0;
`

const Copyright = styled.div`
  font-family: ${props => props.theme.font.secondary};
  ${props => props.theme.font_size.footer};
  color: ${props => props.theme.color.black.regular};
  margin-left: 30px;
  margin-right: 43%;
  float: left;

  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-right: 20%;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`

const RedSquare = styled.div`
  background-color: red;
  padding: 1.5%;
  right: 0px;
  top: 0px;
  height: 100%;
  position: absolute;
`

const FooterDevportalSpan = styled.span`
  margin-right: 10px;
`