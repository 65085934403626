import React from "react";
import { Box, Grid } from '@material-ui/core';

// component
import { Typography } from "../../../../components/Wrappers/Wrappers";

// style
import useStyles from "./styles";

export function RightLayout(props) {
  var classes = useStyles();
  
  
  return (
    <>
      <Box className={classes.widget}>
        <Grid container spacing={2}>
          <Typography size="xl" weight="medium" color="secondary" className={classes.right}>{props.title}</Typography>
          <Grid item xs={6} sm container direction="column"><Typography size="md" color="tertiary" className={classes.description}>{props.description}</Typography></Grid>
          <Grid item xs={6} sm container direction="column"><Typography size="md" color="tertiary" className={classes.description}>{props.description2}</Typography></Grid>
        </Grid>
      </Box>
    </>
  );
}

export function RightLayoutImg(props) {
  var classes = useStyles();
  
  
  return (
    <>
      <Box className={classes.widget}>
        <Grid container spacing={2}>
          <Typography size="xl" weight="medium" color="secondary" className={classes.right}>{props.title}</Typography>
          <Grid item><img className={classes.imgLeft} src={props.img} alt={props.img} /></Grid>
          <Grid item xs={6} sm container direction="column"><Typography size="md" color="tertiary" className={classes.description}>{props.description}</Typography></Grid>
        </Grid>
      </Box>
    </>
  );
}

export function LeftLayout(props) {
  var classes = useStyles();
  
  
  return (
    <>
    <Box className={classes.widget}>
        <Grid container spacing={2}>
          <Typography size="xl" weight="medium" color="secondary" className={classes.left}>{props.title}</Typography>
          <Grid item xs={6} sm container direction="column"><Typography size="md" color="tertiary" className={classes.description}>{props.description}</Typography></Grid>
          <Grid item xs={6} sm container direction="column"><Typography size="md" color="tertiary" className={classes.description}>{props.description2}</Typography></Grid>
        </Grid>
      </Box>
    </>
  );
}

export function LeftLayoutImg(props) {
  var classes = useStyles();
  
  
  return (
    <>
    <Box className={classes.widget}>
    <Grid container spacing={2}>
          <Typography size="xl" weight="medium" color="secondary" className={classes.left}>{props.title}</Typography>
          <Grid item xs={6} sm container direction="column"><Typography size="md" color="tertiary" className={classes.description}>{props.description}</Typography></Grid>
          <Grid item><img className={classes.imgRight} src={props.img} alt={props.img} /></Grid>
        </Grid>
      </Box>
    </>
  );
}
