import React, { useState } from 'react'
import { LanguageContext } from '../context/languageContext'
import ReactHtmlParser from 'react-html-parser'

import ENG from './en.json'
import FRA from './fr.json'

export default function Translate(props) {
  const [langs] = useState({ ENG, FRA })
  const { string } = props

  return (
    <LanguageContext.Consumer>
      {value => ReactHtmlParser(langs[value][string])}
    </LanguageContext.Consumer>
  )
}
