import React, { useState, useEffect } from 'react'
import { Row } from 'react-bootstrap'
import { Section, Container } from 'components/global'
import Loader from 'react-loader-spinner'
import axios from 'axios'
import './catalogue.css'
import CatalogueCardWrapper from '../catalogueCardWrapper'

export default function Catalogue() {
  const [products, setProducts] = useState([])
  useEffect(() => {
    fetchProducts().then(response => {
      let products = response.data
      // On transforme descriptions, on remet les language en index de tableau
      products = products.map(product => {
        const descriptionsByLanguage = product.descriptions.reduce(
          (memo, { language, ...message }) => {
            memo[language] = message
            return memo
          },
          {}
        )
        return {
          ...product,
          descriptions: descriptionsByLanguage
        }
      })
      setProducts(products)
    })
  }, [])

  if (products && !products.length) {
    return (
      <Section id="catalogue">
        <div id="loader">
          <Loader type="Triangle" color="#FF0000" height="100" width="100" />
        </div>
      </Section>
    )
  }

  async function fetchProducts() {
    return axios.get(process.env.REACT_APP_API_PRODUCTS)
  }

  return (
    <Section id="catalogue">
      <Container>
        <Row>
          {products.map((product, index) => {
            return <CatalogueCardWrapper product={product} key={index} />
          })}
        </Row>
      </Container>
    </Section>
  )
}
