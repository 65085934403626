import React from 'react'
import { LanguageContext } from 'context/languageContext'
import './privacy.css'
import { Section, Container } from 'components/global'

export default function Privacy() {
  return (
    <LanguageContext.Consumer>
      {value =>
        value === 'fr' ? (
          <Section id="privacy">
            <Container>
              <h1>Privacy</h1>
              <br />
              <h2>Privacy Statement</h2>
              <p>
                Crédit Mutuel ARKEA acknowledges the particular importance of
                data protection and data security with legal, professional and
                ethical integrity.
              </p>
              <p>
                Crédit Mutuel ARKEA shall comply with data protection laws and
                more specifically, Regulation (EU) 2016/679 of the European
                Parliament and of the Council of 27 April 2016 on the protection
                of natural persons with regard to the processing of personal
                data and on the free movement of such data and any other act
                governing the processing and use of Personal Data ("Data
                Protection Laws"), and shall not do anything, or permit anything
                to be done, which might lead to a violation by itself of these
                laws.
              </p>
              <p>
                In addition to the Data Protection Laws aimed at protecting the
                privacy of natural persons, Crédit Mutuel ARKEA acknowledges
                that there are a series of rules, recommendations and guidelines
                from banking authorities (including where the processing of data
                is outsourced), technical standards in the banking sector, and
                best practices, aimed at protecting, in the interest of
                Customers and banks themselves, the security of any form of data
                which is inherent in the operation of a banking platform, i.e.,
                to protect such data against accidental or unlawful destruction
                or accidental loss, alteration, unauthorized disclosure or
                access, in particular where the processing involves the
                transmission of personal data over a network, and against all
                other unlawful forms of processing, (the "Security Rules").
                Crédit Mutuel ARKEA shall therefore also implement technical and
                organizational measures in order to comply with such Security
                Rules.
              </p>
              <p>
                This Privacy Statement informs you of the conditions under which
                we, Crédit Mutuel ARKEA process, collect, record, store, adjust,
                organise, use, transfer or delete your personal data. It also
                informs you of the rights you have over your data.
              </p>

              <h3>What is personal data ?</h3>
              <p>
                This is any information relating to an identified or
                identifiable natural person.
              </p>
              <p>
                An identifiable natural person is one who can be identified,
                directly or indirectly, in particular by reference to an
                identifier such as a name, an identification number, location
                data, an online identifier or to one or more factors specific to
                the physical, physiological, genetic, mental, economic, cultural
                or social identity of that natural person.
              </p>

              <h3>What categories of personal data do we process ?</h3>
              <p>
                The following categories of personal data are mainly processed
                in the context of our discussions :
              </p>

              <ul>
                <li>
                  declarative personal data that we may collect directly from
                  you or data collected indirectly from third parties with whom
                  we have discussions.
                </li>
                <li>
                  personal data related to the operation of services, generated
                  in particular when using online services.
                </li>
                <li>
                  personal data derived from public sources (e.g. public part of
                  social networks) in compliance with regulations.
                </li>
              </ul>

              <p>Personal data collected includes:</p>

              <ul>
                <li>Name and surname of the developer.</li>
                <li>job title of the developer.</li>
                <li>e-mail address of the developer.</li>
              </ul>

              <h3>Why do we use your personal data?</h3>
              <p>
                We use your personal data responsibly, for a specific and useful
                purposes :
              </p>

              <ul>
                <li>
                  To provide anonymous computer applications and data models to
                  an authorised audience.
                </li>
                <li>
                  To secure and develop our activity while pursuing our
                  legitimate interests
                </li>
                <p>
                  These data processing operations aim to promote our customer
                  satisfaction and our growth, while respecting your fundamental
                  rights and freedoms.
                </p>
                <p>
                  The common objective for both ASPSPs (Account Servicing
                  Payment Service providers) and TPP (Third Party Providers) is
                  to improve the service provided to our customers in compliance
                  with current regulations while securing our activities and
                  cash flows.
                </p>
                <li>To Comply with the law</li>
                <p>
                  Like any financial institution, we are subject to rigorous
                  control of our activities, to which we lend ourselves with the
                  greatest transparency.
                </p>
                <p>It includes:</p>
                <ul>
                  <li>
                    Responding to requests from administrative and judicial
                    authorities
                  </li>
                  <li>Combating money laundering and terrorist financing</li>
                  <li>Combating market abuse and insider trading</li>
                  <li>Make regulatory declarations</li>
                  <li>Implement the best execution policy</li>
                </ul>
                <br />
                <li>To justify our activities</li>
                <p>
                  To produce activity monitoring reports and regulatory reports.
                </p>
                <p>To manage operational risks</p>
              </ul>

              <h3>How long do we keep your personal data ?</h3>
              <p>
                The storage periods we apply are compliant with Data Protection
                Laws and must not exceed the time strictly necessary for the
                proper execution of the processing.
              </p>
              <p>To determine duration, we took into account:</p>

              <ul>
                <li>The purposes for which these data are collected.</li>
                <li>The persons concerned by the collection.</li>
                <li>
                  Compliance with legal, regulatory or professionally recognized
                  obligations to which we are bound.
                </li>
              </ul>

              <h3>Is your personal data shared with third parties ?</h3>
              <p>
                We consider that all data concerning you constitute confidential
                data subject to the banking secrecy to which we are subject.
              </p>
              <p>
                For the purposes detailed above, we might have to transfer your
                personal data with the competent operating entities of the
                banking world, their entities, theirs service providers,
                subcontractors and legally authorized administrative and
                judicial authorities (European Union or non-EU).
              </p>
              <p>
                In our commercial relations, we make sure that our partners
                comply with Data Protection Laws.
              </p>
              <br />
              <h2>Our security system, your rights</h2>
              <br />
              <h3>How do we protect your personal data ?</h3>
              <p>
                Banking regulations require us to ensure a high level of
                security and confidentiality of your personal data. Each and
                single treatment of this data is thus carried out within the
                framework of strict rules and procedures.
              </p>

              <h4>A reliable information system</h4>
              <p>
                We take the security of your data very seriously. In view of the
                nature of the personal data and the potential risks involved in
                the processing, we have taken a number of necessary measures to
                preserve and prevent them from being distorted, damaged, made
                inaccessible or accessed by unauthorized third parties:
              </p>
              <ul>
                <li>Technical measures, such as data encryption.</li>
                <li>
                  Physical measures, such as strict access control to buildings.
                </li>
                <li>
                  Additional measures such as audit trails of our activities
                  also help us to ensure an optimal level of security.
                </li>
              </ul>

              <h4>Sensitized employees</h4>
              <p>
                We have also taken organizational measures, through the training
                of teams dedicated to the issue of information security.
              </p>
              <p>
                More generally, we make all our employees aware of the
                protection of personal data and ensure that they comply with the
                regulations in force and the ethics of our company.
              </p>

              <h4>Trusted partners</h4>
              <p>
                We choose subcontractors or service providers who offer a high
                level of guarantees regarding the implementation of appropriate
                technical and organizational measures. As a result, we ensure
                that the processing of your data complies with the requirements
                of current regulations concerning the protection of personal
                data.
              </p>

              <h4>An unfailing reactivity</h4>
              <p>
                If we notice an incident with an impact on personal data, we
                ensure, according to the framework imposed by the regulations,
                to notify it to the proper supervisory authority, ) as soon as
                possible after having read it and to inform the persons
                concerned.
              </p>
              <p>
                Our prospects, customers and other actors interfering with us
                (especially on IT security monitoring) can also contribute to
                maintaining our level of security with regard to personal data.
                We oblige them to respect the rules we recommend and to inform
                us of any anomalies they may encounter.
              </p>

              <h3>What are your rights and how can you enforce them?</h3>
              <p>
                You have dedicated rights over your personal data such as the
                right to access, rectify, delete information, limit processing,
                object to processing and automated decisions, ask the transfer
                of your personal data (portability) and carry your personal
                data. You also have the right to define instructions regarding
                the storage, erasure and communication of your personal data
                after your death. Finally, you have a right of complaint to the
                Commission Nationale Informatique et Libertés (CNIL).
              </p>

              <h3>How to exercise your rights ?</h3>
              <p>
                You can exercise your rights to the following email address :
                relations.clientele@cmb.fr
              </p>
              <p>
                The contact details of the Data Protection Officer are as
                follows: protectiondesdonnées@arkea.com
              </p>
              <p>
                To exercise your right of objection, you may invoke it for
                processing operations based on a legitimate interest or a
                mission in the public interest.
              </p>
              <p>
                Your request for the right to forget may, in certain cases, not
                be successful. For example if we are required by law to retain
                your data beyond the duration of the relation.
              </p>

              <h2>Help you manage your navigation data</h2>
              <p>
                <b>
                  Cookies are small "test" files issued by the sites you visit.
                </b>
              </p>
              <p>
                Recorded on your device (smartphone, tablet, computer, etc.),
                they store temporary or persistent data. Very concretely, they
                inform, for example, the site on which you are browsing of the
                date of your last visit or your display preferences, i.e. the
                number of articles to be displayed per page, for example.
              </p>
              <p>
                In order to improve your experience, improve our services or
                facilitate navigation, we implement two types of cookies on your
                computer terminals :
              </p>
              <ul>
                <li>
                  Session cookies, also known as comfort cookies, whose sole
                  purpose is to maintain your "session context". In particular,
                  they avoid multiple authentications during the same visit. The
                  data collected in this context is automatically destroyed
                  about half an hour after the end of your browsing.
                </li>
                <li>
                  Functional cookies which are anonymous cookies used to ensure
                  that the website operates smoothly. Without them, the website
                  would not work properly. Functional cookies remember your
                  settings, for example the language in which the website is
                  shown. But they cannot identify you personally.
                </li>
                <li>
                  Persistent cookies called audience analysis cookies. They are
                  mainly used to compile mass statistics to improve the quality
                  of presentation of our information or the ergonomics of our
                  services.
                </li>
                <p>
                  The maximum storage period for these cookies is 13 months.
                </p>
                <li>
                  Third-party cookies to evaluate the performance of our
                  advertising campaigns or that may give us the ability to send
                  you targeted commercial messages on the advertising space of
                  third party websites.The maximum storage period for these
                  cookies is set at 14 months.
                </li>
              </ul>

              <h2>How to set your cookies</h2>
              <p>
                You can prevent cookies (or equivalent technology) from being
                stored by selecting the appropriate browser settings on your
                computer, smartphone, tablet or any other interface.
              </p>
              <p>
                The persistent and third-party cookies described above are
                implemented if you continue browsing outside the "No, give me
                more info" link in the "cookie banner". Please note that this
                banner is automatically displayed as soon as you enter a site
                that has implemented cookies, in order to ensure that you give
                your consent at all times to the collection of your browsing
                data.
              </p>

              <h2>The case of Google Analytics audience measurement tools </h2>
              <p>
                The Google Analytics audience measurement tool equips our
                digital media available to you. The data generated by the tool
                and its cookies are stored by Google on servers located in
                Europe and the United States.
              </p>
              <p>
                On our sites, we have taken care to set up Google Analytics with
                the "Anonymize IP" option, which ensures that your IP (Internet
                Protocol) address is always treated anonymously. To learn more
                about Google's privacy policies, please{' '}
                <a href="https://policies.google.com/privacy?hl=en&gl=en">
                  click here.
                </a>
              </p>
              <p>
                For the sake of transparency, we also inform you that there is a
                system in place to prevent Google Analytics from monitoring. For
                more information, please visit the Google website by{' '}
                <a href="https://tools.google.com/dlpage/gaoptout?hl=en">
                  clicking here.
                </a>
              </p>

              <h2>Are the rules changing ? We're informing you !</h2>
              <p>
                In order to provide you with an optimal browsing experience, the
                rules for cookie management and audience measurement described
                above may change. All processing changes or revisions will be
                recorded on this page and reported by updating the publication
                date.
              </p>

              <p>Date of publication: 04/10/2019</p>
            </Container>
          </Section>
        ) : (
          <Section id="privacy">
            <Container>
              <h1>Privacy</h1>
              <br />
              <h2>Privacy Statement</h2>
              <p>
                Crédit Mutuel ARKEA acknowledges the particular importance of
                data protection and data security with legal, professional and
                ethical integrity.
              </p>
              <p>
                Crédit Mutuel ARKEA shall comply with data protection laws and
                more specifically, Regulation (EU) 2016/679 of the European
                Parliament and of the Council of 27 April 2016 on the protection
                of natural persons with regard to the processing of personal
                data and on the free movement of such data and any other act
                governing the processing and use of Personal Data ("Data
                Protection Laws"), and shall not do anything, or permit anything
                to be done, which might lead to a violation by itself of these
                laws.
              </p>
              <p>
                In addition to the Data Protection Laws aimed at protecting the
                privacy of natural persons, Crédit Mutuel ARKEA acknowledges
                that there are a series of rules, recommendations and guidelines
                from banking authorities (including where the processing of data
                is outsourced), technical standards in the banking sector, and
                best practices, aimed at protecting, in the interest of
                Customers and banks themselves, the security of any form of data
                which is inherent in the operation of a banking platform, i.e.,
                to protect such data against accidental or unlawful destruction
                or accidental loss, alteration, unauthorized disclosure or
                access, in particular where the processing involves the
                transmission of personal data over a network, and against all
                other unlawful forms of processing, (the "Security Rules").
                Crédit Mutuel ARKEA shall therefore also implement technical and
                organizational measures in order to comply with such Security
                Rules.
              </p>
              <p>
                This Privacy Statement informs you of the conditions under which
                we, Crédit Mutuel ARKEA process, collect, record, store, adjust,
                organise, use, transfer or delete your personal data. It also
                informs you of the rights you have over your data.
              </p>

              <h3>What is personal data ?</h3>
              <p>
                This is any information relating to an identified or
                identifiable natural person.
              </p>
              <p>
                An identifiable natural person is one who can be identified,
                directly or indirectly, in particular by reference to an
                identifier such as a name, an identification number, location
                data, an online identifier or to one or more factors specific to
                the physical, physiological, genetic, mental, economic, cultural
                or social identity of that natural person.
              </p>

              <h3>What categories of personal data do we process ?</h3>
              <p>
                The following categories of personal data are mainly processed
                in the context of our discussions :
              </p>

              <ul>
                <li>
                  declarative personal data that we may collect directly from
                  you or data collected indirectly from third parties with whom
                  we have discussions.
                </li>
                <li>
                  personal data related to the operation of services, generated
                  in particular when using online services.
                </li>
                <li>
                  personal data derived from public sources (e.g. public part of
                  social networks) in compliance with regulations.
                </li>
              </ul>

              <p>Personal data collected includes:</p>

              <ul>
                <li>Name and surname of the developer.</li>
                <li>job title of the developer.</li>
                <li>e-mail address of the developer.</li>
              </ul>

              <h3>Why do we use your personal data?</h3>
              <p>
                We use your personal data responsibly, for a specific and useful
                purposes :
              </p>

              <ul>
                <li>
                  To provide anonymous computer applications and data models to
                  an authorised audience.
                </li>
                <li>
                  To secure and develop our activity while pursuing our
                  legitimate interests
                </li>
                <p>
                  These data processing operations aim to promote our customer
                  satisfaction and our growth, while respecting your fundamental
                  rights and freedoms.
                </p>
                <p>
                  The common objective for both ASPSPs (Account Servicing
                  Payment Service providers) and TPP (Third Party Providers) is
                  to improve the service provided to our customers in compliance
                  with current regulations while securing our activities and
                  cash flows.
                </p>
                <li>To Comply with the law</li>
                <p>
                  Like any financial institution, we are subject to rigorous
                  control of our activities, to which we lend ourselves with the
                  greatest transparency.
                </p>
                <p>It includes:</p>
                <ul>
                  <li>
                    Responding to requests from administrative and judicial
                    authorities
                  </li>
                  <li>Combating money laundering and terrorist financing</li>
                  <li>Combating market abuse and insider trading</li>
                  <li>Make regulatory declarations</li>
                  <li>Implement the best execution policy</li>
                </ul>
                <br />
                <li>To justify our activities</li>
                <p>
                  To produce activity monitoring reports and regulatory reports.
                </p>
                <p>To manage operational risks</p>
              </ul>

              <h3>How long do we keep your personal data ?</h3>
              <p>
                The storage periods we apply are compliant with Data Protection
                Laws and must not exceed the time strictly necessary for the
                proper execution of the processing.
              </p>
              <p>To determine duration, we took into account:</p>

              <ul>
                <li>The purposes for which these data are collected.</li>
                <li>The persons concerned by the collection.</li>
                <li>
                  Compliance with legal, regulatory or professionally recognized
                  obligations to which we are bound.
                </li>
              </ul>

              <h3>Is your personal data shared with third parties ?</h3>
              <p>
                We consider that all data concerning you constitute confidential
                data subject to the banking secrecy to which we are subject.
              </p>
              <p>
                For the purposes detailed above, we might have to transfer your
                personal data with the competent operating entities of the
                banking world, their entities, theirs service providers,
                subcontractors and legally authorized administrative and
                judicial authorities (European Union or non-EU).
              </p>
              <p>
                In our commercial relations, we make sure that our partners
                comply with Data Protection Laws.
              </p>
              <br />
              <h2>Our security system, your rights</h2>
              <br />
              <h3>How do we protect your personal data ?</h3>
              <p>
                Banking regulations require us to ensure a high level of
                security and confidentiality of your personal data. Each and
                single treatment of this data is thus carried out within the
                framework of strict rules and procedures.
              </p>

              <h4>A reliable information system</h4>
              <p>
                We take the security of your data very seriously. In view of the
                nature of the personal data and the potential risks involved in
                the processing, we have taken a number of necessary measures to
                preserve and prevent them from being distorted, damaged, made
                inaccessible or accessed by unauthorized third parties:
              </p>
              <ul>
                <li>Technical measures, such as data encryption.</li>
                <li>
                  Physical measures, such as strict access control to buildings.
                </li>
                <li>
                  Additional measures such as audit trails of our activities
                  also help us to ensure an optimal level of security.
                </li>
              </ul>

              <h4>Sensitized employees</h4>
              <p>
                We have also taken organizational measures, through the training
                of teams dedicated to the issue of information security.
              </p>
              <p>
                More generally, we make all our employees aware of the
                protection of personal data and ensure that they comply with the
                regulations in force and the ethics of our company.
              </p>

              <h4>Trusted partners</h4>
              <p>
                We choose subcontractors or service providers who offer a high
                level of guarantees regarding the implementation of appropriate
                technical and organizational measures. As a result, we ensure
                that the processing of your data complies with the requirements
                of current regulations concerning the protection of personal
                data.
              </p>

              <h4>An unfailing reactivity</h4>
              <p>
                If we notice an incident with an impact on personal data, we
                ensure, according to the framework imposed by the regulations,
                to notify it to the proper supervisory authority, ) as soon as
                possible after having read it and to inform the persons
                concerned.
              </p>
              <p>
                Our prospects, customers and other actors interfering with us
                (especially on IT security monitoring) can also contribute to
                maintaining our level of security with regard to personal data.
                We oblige them to respect the rules we recommend and to inform
                us of any anomalies they may encounter.
              </p>

              <h3>What are your rights and how can you enforce them?</h3>
              <p>
                You have dedicated rights over your personal data such as the
                right to access, rectify, delete information, limit processing,
                object to processing and automated decisions, ask the transfer
                of your personal data (portability) and carry your personal
                data. You also have the right to define instructions regarding
                the storage, erasure and communication of your personal data
                after your death. Finally, you have a right of complaint to the
                Commission Nationale Informatique et Libertés (CNIL).
              </p>

              <h3>How to exercise your rights ?</h3>
              <p>
                You can exercise your rights to the following email address :
                relations.clientele@cmb.fr
              </p>
              <p>
                The contact details of the Data Protection Officer are as
                follows: protectiondesdonnées@arkea.com
              </p>
              <p>
                To exercise your right of objection, you may invoke it for
                processing operations based on a legitimate interest or a
                mission in the public interest.
              </p>
              <p>
                Your request for the right to forget may, in certain cases, not
                be successful. For example if we are required by law to retain
                your data beyond the duration of the relation.
              </p>

              <h2>Help you manage your navigation data</h2>
              <p>
                <b>
                  Cookies are small "test" files issued by the sites you visit.
                </b>
              </p>
              <p>
                Recorded on your device (smartphone, tablet, computer, etc.),
                they store temporary or persistent data. Very concretely, they
                inform, for example, the site on which you are browsing of the
                date of your last visit or your display preferences, i.e. the
                number of articles to be displayed per page, for example.
              </p>
              <p>
                In order to improve your experience, improve our services or
                facilitate navigation, we implement two types of cookies on your
                computer terminals :
              </p>
              <ul>
                <li>
                  Session cookies, also known as comfort cookies, whose sole
                  purpose is to maintain your "session context". In particular,
                  they avoid multiple authentications during the same visit. The
                  data collected in this context is automatically destroyed
                  about half an hour after the end of your browsing.
                </li>
                <li>
                  Functional cookies which are anonymous cookies used to ensure
                  that the website operates smoothly. Without them, the website
                  would not work properly. Functional cookies remember your
                  settings, for example the language in which the website is
                  shown. But they cannot identify you personally.
                </li>
                <li>
                  Persistent cookies called audience analysis cookies. They are
                  mainly used to compile mass statistics to improve the quality
                  of presentation of our information or the ergonomics of our
                  services.
                </li>
                <p>
                  The maximum storage period for these cookies is 13 months.
                </p>
                <li>
                  Third-party cookies to evaluate the performance of our
                  advertising campaigns or that may give us the ability to send
                  you targeted commercial messages on the advertising space of
                  third party websites.The maximum storage period for these
                  cookies is set at 14 months.
                </li>
              </ul>

              <h2>How to set your cookies</h2>
              <p>
                You can prevent cookies (or equivalent technology) from being
                stored by selecting the appropriate browser settings on your
                computer, smartphone, tablet or any other interface.
              </p>
              <p>
                The persistent and third-party cookies described above are
                implemented if you continue browsing outside the "No, give me
                more info" link in the "cookie banner". Please note that this
                banner is automatically displayed as soon as you enter a site
                that has implemented cookies, in order to ensure that you give
                your consent at all times to the collection of your browsing
                data.
              </p>

              <h2>The case of Google Analytics audience measurement tools </h2>
              <p>
                The Google Analytics audience measurement tool equips our
                digital media available to you. The data generated by the tool
                and its cookies are stored by Google on servers located in
                Europe and the United States.
              </p>
              <p>
                On our sites, we have taken care to set up Google Analytics with
                the "Anonymize IP" option, which ensures that your IP (Internet
                Protocol) address is always treated anonymously. To learn more
                about Google's privacy policies, please{' '}
                <a href="https://policies.google.com/privacy?hl=en&gl=en">
                  click here.
                </a>
              </p>
              <p>
                For the sake of transparency, we also inform you that there is a
                system in place to prevent Google Analytics from monitoring. For
                more information, please visit the Google website by{' '}
                <a href="https://tools.google.com/dlpage/gaoptout?hl=en">
                  clicking here.
                </a>
              </p>

              <h2>Are the rules changing ? We're informing you !</h2>
              <p>
                In order to provide you with an optimal browsing experience, the
                rules for cookie management and audience measurement described
                above may change. All processing changes or revisions will be
                recorded on this page and reported by updating the publication
                date.
              </p>

              <p>Date of publication: 04/10/2019</p>
            </Container>
          </Section>
        )
      }
    </LanguageContext.Consumer>
  )
}
