import styled from 'styled-components'
import { primaryTextFontColor, primaryNavBackgroundColor } from 'styles/theme'

export const Nav = styled.nav`
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  border-bottom: 1px solid #f7f7f7;
  background-color: ${primaryNavBackgroundColor};
  height: 90px;
  display: flex;
  align-items: center;

  .nav-item {
    display: block;
    position: relative;
    height: 100%;
    text-align: center;
  }

  .menu-wrap {
    display: flex;
    height: 100%;
    width: 100%;
  }
`

export const NavListWrapper = styled.div`
  display: block;
  position: relative;
  height: 100%;
  text-align: center;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    height: 100%;

    ${({ mobile }) =>
      mobile &&
      `
        flex-direction: column;
        margin-top: 1em;

        > ${NavItem} {
          margin: 0;
          margin-top: 0.75em;
        }
      `};
  }
`

export const NavItem = styled.li`
  height: 100%;
  margin: 0 0.75em;
  font-family: ${props => props.theme.font.secondary};
  ${props => props.theme.font_size.headerfooter};
  align-items: center;
  justify-content: center;
  display: flex;

  a {
    text-decoration: none;
    color: ${primaryTextFontColor};
  }

  &:hover {
    background-color: #f7f7f7;
  }

  &.active {
    border-bottom: 2px solid ${props => props.theme.color.primary};
  }
`

export const MobileMenu = styled.div`
  position: absolute;
  top: 90px;
  left: 0;
  width: 100%;
  background: #fff;
`

export const Brand = styled.div`
  font-family: ${props => props.theme.font.primary};
  ${props => props.theme.font_size.large};
  margin-right: auto;
  padding-left: 20px;
  text-align: center;

  img {
    height: 42px;
    margin-top: 25px;
    margin-right: 10px;
  }
`

export const Mobile = styled.div`
  display: none !important;

  @media screen and (max-width: 1100px) {
    display: block !important;
  }

  ${props =>
    props.hide &&
    `
    display: block  !important;

    @media screen and (max-width: 1100px) {
      display: none  !important;
    }
  `}
`
export const Burger = styled.div`
  display: none !important;
  padding: 10px;

  @media screen and (max-width: 1100px) {
    display: flex !important;
  }
`
