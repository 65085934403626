import React from "react";
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../components/PageTitle/PageTitle";


const datatableData = [
  ["POST", "/log/v1", "http://localhost:3000/dummy", "200" ,'{ "timestamp": <TIMESTAMP-OF-EVENT>, "message": "User "xyz" logged in", "service": "login-service", "hostname": "login.example.com" }'],
  ["POST", "/log/v1", "http://localhost:3000/dummy", "200" ,'{ "timestamp": <TIMESTAMP-OF-EVENT>, "message": "User "xyz" logged in", "service": "login-service", "hostname": "login.example.com" }'],
  ["POST", "/log/v1", "http://localhost:3000/pet", "201" ,'{ "timestamp": <TIMESTAMP-OF-EVENT>, "message": "User "xyz" logged in", "service": "login-service", "hostname": "login.example.com" }'],
  ["GET", "/log/v1", "http://localhost:3000/dummy", "200" ,'{ "timestamp": <TIMESTAMP-OF-EVENT>, "message": "User "xyz" logged in", "service": "login-service", "hostname": "login.example.com" }'],
  ["GET", "/log/v1", "http://localhost:3000/dummy", "404" ,'{ "timestamp": <TIMESTAMP-OF-EVENT>, "message": "User "xyz" logged in", "service": "login-service", "hostname": "login.example.com" }'],
  ["GET","/log/v1", "http://localhost:3000/pet", "200" ,'{ "timestamp": <TIMESTAMP-OF-EVENT>, "message": "User "xyz" logged in", "service": "login-service", "hostname": "login.example.com" }'],
  ["POST", "/log/v1", "http://localhost:3000/pet", "200" ,'{ "timestamp": <TIMESTAMP-OF-EVENT>, "message": "User "xyz" logged in", "service": "login-service", "hostname": "login.example.com" }'],
  ["DELETE", "/log/v1", "http://localhost:3000/toto", "200" ,'{ "timestamp": <TIMESTAMP-OF-EVENT>, "message": "User "xyz" logged in", "service": "login-service", "hostname": "login.example.com" }'],
  ["GET", "/log/v1", "http://localhost:3000/toto", "200" ,'{ "timestamp": <TIMESTAMP-OF-EVENT>, "message": "User "xyz" logged in", "service": "login-service", "hostname": "login.example.com" }'],
  ["GET","/log/v1", "http://localhost:3000/toto", "503" ,'{ "timestamp": <TIMESTAMP-OF-EVENT>, "message": "User "xyz" logged in", "service": "login-service", "hostname": "login.example.com" }'],
];
datatableData.forEach(function(element) {
  element.splice(0, 0, new Date(+(new Date()) - Math.floor(Math.random()*10000000000)).toLocaleDateString())}//.toISOString().slice(0,-5))}
);

export default function Logs() {
  return (
    <>
      <PageTitle title="My Logs" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MUIDataTable
            title="All Apis logs"
            data={datatableData}
            columns={["Date","Method", "Path", "Uri", "responseCode", "message"]}
            options={{
              filterType: "dropdown",
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
