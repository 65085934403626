import styled from 'styled-components'
import {
  primaryBackgroundColor,
  primaryHeaderFontColor,
  primaryTextFontColor
} from 'styles/theme'

export const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;

  @media (min-width: ${props => props.theme.screen.xs}) {
    max-width: 540px;
  }

  @media (min-width: ${props => props.theme.screen.sm}) {
    max-width: 720px;
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    max-width: 960px;
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    max-width: 1200px;
  }

  ${props =>
    props.fluid &&
    `
    max-width: 1200px !important;
  `};

  a,
  p,
  li {
    color: ${primaryTextFontColor};
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${primaryHeaderFontColor};
  }
`

export const Section = styled.section`
  padding: 50px 0;
  overflow: hidden;

  @media (max-width: ${props => props.theme.screen.md}) {
    padding: 96px 0;
  }
  background-color: ${primaryBackgroundColor}
    ${props =>
      props.accent &&
      `background-color: ${
        props.accent === 'secondary'
          ? props.theme.color.white.regular
          : props.theme.color.white.dark
      }`};
`
