import React from 'react'
import './termsOfUse.css'
import { LanguageContext } from 'context/languageContext'
import { Section, Container } from 'components/global'
import CustomButton from '../CustomButton/CustomButton'

export default function TermsOfUse() {
  return (
    <LanguageContext.Consumer>
      {value =>
        value === 'fr' ? (
          <Section id="termsofuse">
            <Container>
              <h1>Terms of use</h1>
              <h2>Terms of use of the Arkea Developer Portal</h2>
              <p>
                The Arkea Developer Portal (the “Portal”) was created by Crédit
                Mutuel Arkea (“Arkea”), a variable credit cooperative and
                insurance brokerage with limited liability, Collective
                authorization No. 323, business registration number 775 577 018
                (Brest Trade and Companies Register), located at 1, Rue Louis
                Lichou, 29480 Le Relecq-Kerhuon, France.
              </p>
              <p>
                The access, the use and the registration of the Portal are
                subject to the general legal notice which is located{' '}
                <a href="https://www.arkea.com/banque/assurance/credit/mutuel/ecb_5055/en/legal-notices?nav=public">
                  here
                </a>
                , but also to the following Terms of Use (the “Terms”);
              </p>
              <p>
                The Terms represent all the obligations set between Arkea and
                you in relation to the registration and access to the Portal as
                well as its use. The Terms replace or cancel any other previous
                written or spoken obligations set between Arkea and you relating
                to the Portal.
              </p>
              <p>
                The Portal allows you to create applications, view our
                documentation, and request support through the use of
                Application Programming Interfaces (the “API”s) and the use of a
                test environment (the “Sandbox”).
              </p>

              <h2>Registration and access to The Portal</h2>
              <p>
                The use of these services including but not limited to the
                Sandbox and the APIs require you to register on the Portal and
                to create a Developer Account.
              </p>
              <p>
                You acknowledge and agree that the information you provide to
                Arkea during this registration is accurate and is up to date as
                long as you are registered.
              </p>
              <p>
                You also agree take any reasonable measure to keep your login
                credentials secret and not to reveal them to anyone. You are
                responsible for any damage caused by someone using your login
                credentials.
              </p>
              <p>
                If you become aware that the confidentiality of your login
                credentials has been compromised, you must inform Arkea about
                this without delay.
              </p>
              <p>
                Arkea may decide to accept or reject your registration request
                at its discretion for any justified reason.
              </p>
              <p>
                Your software application might be granted access to specific
                APIs and to a Sandbox, if Arkea decides to do so.
              </p>
              <p>
                The data available to you through the Sandbox will only be
                testing data.
              </p>
              <p>
                You agree not to send any actual user data through the Sandbox.
              </p>

              <h2>API specifics</h2>
              <p>
                The use of the APIs may include additional terms and
                registration requirements, which will be available to you in the
                overview section of these APIs.
              </p>

              <h2>User’s obligations and liability</h2>
              <p>
                By accessing the Portal, you acknowledge and agree having read
                and understood that you are to be bound by these Terms. If you
                register on behalf of a legal entity, you acknowledge that you
                are legally authorized to bind that entity to those terms.
              </p>
              <p>
                By using the Portal, you agree that you do so at your own risk.
                The information on the Portal is offered “as is” and is subject
                to change.
              </p>
              <p>
                Arkea disclaims all warranties and does not provide any
                guarantee as in respect to the Portal being error free.
              </p>
              <p>
                Except in case of gross or willful misconduct or negligence,
                Arkea’s liability will not exceed a maximum annual amount of
                10000 euros, all types of damages taken into account.
              </p>
              <p>
                When using the Portal you must also comply with all the stated
                requirements set forth by Arkea, including but not limited to
                the documentation, the instructions, and any software
                requirements.
              </p>
              <p>
                When using the Portal, you agree not to decompile, reverse
                engineer, unmask, decrypt, create derivative works, modify, copy
                or reconstruct any data accessible through the Sandbox.
              </p>
              <p>
                You agree that if Arkea decides to subcontract parts of the
                Portal, including the Sandbox or the API to a third party, you
                will be bound towards this third party in the same way that you
                are bound to Arkea according to the Terms.
              </p>
              <p>
                In case of an occurrence of any events which are defined by
                French law as constituting “force majeure”, your access and use
                of the Portal may be interrupted.
              </p>
              <p>
                Any websites linked on the Portal have not been analyzed by
                Arkea; as a result Arkea denies any responsibility regarding the
                content of these websites.
              </p>

              <h2>Security</h2>
              <p>
                Unless you have been given a prior express consent by Arkea, you
                will also agree not to disclose to any third party any
                information to which you have access to through the Sandbox and
                the API’s. This restriction applies to, but is not limited to,
                the sale, distribution, the publication, the disclosure, the
                rent, the lease, the sublicense, or the marketing of such
                information.
              </p>
              <p>
                You must not use the Sandbox in any way that would harm the
                Portal’s stability, Arkea’s security or that would prevent
                others from using it. You also agree not to use the Portal in
                order to collect information about other users of the Portal.
                You also agree not to use the Sandbox in any way that would
                expose it or Arkea to malicious software or any security risks
                such as malware or denial of service attacks.
              </p>
              <p>
                Arkea reserves the right to contact you at its own discretion
                and based on the data you entered for the registration.
              </p>

              <h2>Intellectual property</h2>
              <p>
                All content on the Portal is protected by intellectual property
                law. Unless expressly mentioned otherwise, Arkea is the owner of
                all rights, titles, interests, intellectual property in the
                Portal, including the Sandbox, the APIs and its applications.
              </p>
              <p>
                Once registered, you are granted a temporary, limited,
                revocable, non-transferable, non-exclusive and non sublicensable
                license.
              </p>
              <p>
                This license allows you to use the information, the Sandbox and
                the APIs, in order to assess Arkea’s account information and
                payment initiation APIs.
              </p>
              <p>
                You warrant that you have the intellectual property rights to
                anything you create on, or upload to, the Portal, and that it
                does not infringe on any third party’s intellectual property.
              </p>
              <p>
                Arkea disclaims any liability for what you create on, or upload
                to, the Portal.
              </p>
              <p>
                You will guarantee Arkea against any action, complaint, claim,
                or opposition from any person who invokes an intellectual or
                industrial property right. If this does happen, compensation and
                costs of any kind incurred by Arkea (including but not limited
                to attorney’s fees) as well as damages and interests against
                Arkea will be borne by you.
              </p>
              <p>
                Any application you create on the Sandbox of the Portal you will
                own the intellectual property rights off, but Arkea will be
                granted a limited, royalty-free, non-exclusive right to use such
                an application for the Portal.
              </p>
              <p>
                If Arkea creates applications which offer similar functions to
                those you created on the Portal, Arkea will not have any
                obligation towards you.
              </p>
              <p>
                By using the Portal you accept that you will have to respect all
                the intellectual property rights of Arkea, including but not
                limited to its name, brands, logos, signs, drawings or any other
                distinctive sign used by Arkea.
              </p>
              <p>
                The use of the trademark, the copyrights, the logo or any other
                intellectual property or business secret of Arkea is not
                allowed, unless an explicit consent was given by Arkea. Any use
                without express consent given by Arkea of any of those elements
                will constitute an infringement of Arkea’s intellectual property
                and expose you to penalties.
              </p>
              <p>
                If you choose to submit information to Arkea through feedback,
                comments, or suggestions on the Portal, you will be granting
                your Intellectual Property rights to Arkea in order to improve
                the Portal and its related services.
              </p>

              <h2>Privacy</h2>
              <p>
                Information on how Arkea handles your private data is located on
                the Portal’s privacy policy page which is located{' '}
                <CustomButton to="/privacy">here</CustomButton>.
              </p>

              <h2>Future changes</h2>
              <p>
                Arkea may change the content of these Terms, or API specific
                terms, at any time.
              </p>
              <p>Any change made to them will be published on the Portal.</p>
              <p>
                By using the Portal and the APIs after the Terms have been
                changed, your continued use of the Portal will be considered as
                an acceptance of those changes.
              </p>
              <p>
                The contents of the Portal may also be changed at any time by
                Arkea and may affect your applications created on the Sandbox.
                Arkea denies any responsibility regarding any loss or damage
                resulting from this.
              </p>
              <p>
                If any provision of these Terms were to become invalid, the
                validity of the Terms as a whole will remain unaffected.
              </p>

              <h2>Access and use of the Portal</h2>
              <p>
                If you breach the Terms, your registration will be automatically
                revoked.
              </p>
              <p>
                Arkea may, at any time, decide to suspend your access to the
                Portal without prior notice for justified reasons.
              </p>
              <p>
                Arkea will not be liable for any damages or compensation
                incurred in connection with such suspensions or revocations.
              </p>
              <p>
                Arkea will not be liable, directly or indirectly in relation to
                the use of the software or the specifications released in the
                Sandbox.
              </p>
              <p>
                If Arkea does not immediately act on a breach of the Terms, this
                will not mean that Arkea waivers any rights to do so in the
                future.
              </p>
              <p>
                You are liable for all damages resulting directly or indirectly
                from actions, omissions, faults, errors and failures caused by
                you or your subcontractors when using the APIs and the Sandbox
                through the Portal.
              </p>
              <p>
                You agree to indemnify Arkea in case of any loss, damage, cost,
                or expensive, including legal expenses, incurred by Arkea
                connected to your use of the Portal, including any breach of the
                Terms.
              </p>
              <p>
                If you receive any confidential information by using the Portal,
                you agree not to disclose this information to any third party.
                Will be considered as confidential information any information
                that would be treated as confidential by any reasonable third
                party, such as, but not limited to, any information that has
                been tagged as confidential.
              </p>

              <h2>Applicable law and competent jurisdictions</h2>
              <p>
                These Terms are governed by French law. Any conflict regarding
                these Terms will be brought before the competent jurisdictions
                of Brest.
              </p>

              <h2>Miscellaneous</h2>
              <p>
                You will agree that the Portal, including the APIs and the
                Sandbox, may be subject to an audit conducted by the national
                competent authorities. This audit may include any or all of the
                data that you have entered to register on the Portal, any
                applications you created or any data you uploaded to the Portal,
                its Sandbox or its APIs.
              </p>
            </Container>
          </Section>
        ) : (
          <Section id="termsofuse">
            <Container>
              <h1>Terms of use</h1>
              <h2>Terms of use of the Arkea Developer Portal</h2>
              <p>
                The Arkea Developer Portal (the “Portal”) was created by Crédit
                Mutuel Arkea (“Arkea”), a variable credit cooperative and
                insurance brokerage with limited liability, Collective
                authorization No. 323, business registration number 775 577 018
                (Brest Trade and Companies Register), located at 1, Rue Louis
                Lichou, 29480 Le Relecq-Kerhuon, France.
              </p>
              <p>
                The access, the use and the registration of the Portal are
                subject to the general legal notice which is located{' '}
                <a href="https://www.arkea.com/banque/assurance/credit/mutuel/ecb_5055/en/legal-notices?nav=public">
                  here
                </a>
                , but also to the following Terms of Use (the “Terms”);
              </p>
              <p>
                The Terms represent all the obligations set between Arkea and
                you in relation to the registration and access to the Portal as
                well as its use. The Terms replace or cancel any other previous
                written or spoken obligations set between Arkea and you relating
                to the Portal.
              </p>
              <p>
                The Portal allows you to create applications, view our
                documentation, and request support through the use of
                Application Programming Interfaces (the “API”s) and the use of a
                test environment (the “Sandbox”).
              </p>

              <h2>Registration and access to The Portal</h2>
              <p>
                The use of these services including but not limited to the
                Sandbox and the APIs require you to register on the Portal and
                to create a Developer Account.
              </p>
              <p>
                You acknowledge and agree that the information you provide to
                Arkea during this registration is accurate and is up to date as
                long as you are registered.
              </p>
              <p>
                You also agree take any reasonable measure to keep your login
                credentials secret and not to reveal them to anyone. You are
                responsible for any damage caused by someone using your login
                credentials.
              </p>
              <p>
                If you become aware that the confidentiality of your login
                credentials has been compromised, you must inform Arkea about
                this without delay.
              </p>
              <p>
                Arkea may decide to accept or reject your registration request
                at its discretion for any justified reason.
              </p>
              <p>
                Your software application might be granted access to specific
                APIs and to a Sandbox, if Arkea decides to do so.
              </p>
              <p>
                The data available to you through the Sandbox will only be
                testing data.
              </p>
              <p>
                You agree not to send any actual user data through the Sandbox.
              </p>

              <h2>API specifics</h2>
              <p>
                The use of the APIs may include additional terms and
                registration requirements, which will be available to you in the
                overview section of these APIs.
              </p>

              <h2>User’s obligations and liability</h2>
              <p>
                By accessing the Portal, you acknowledge and agree having read
                and understood that you are to be bound by these Terms. If you
                register on behalf of a legal entity, you acknowledge that you
                are legally authorized to bind that entity to those terms.
              </p>
              <p>
                By using the Portal, you agree that you do so at your own risk.
                The information on the Portal is offered “as is” and is subject
                to change.
              </p>
              <p>
                Arkea disclaims all warranties and does not provide any
                guarantee as in respect to the Portal being error free.
              </p>
              <p>
                Except in case of gross or willful misconduct or negligence,
                Arkea’s liability will not exceed a maximum annual amount of
                10000 euros, all types of damages taken into account.
              </p>
              <p>
                When using the Portal you must also comply with all the stated
                requirements set forth by Arkea, including but not limited to
                the documentation, the instructions, and any software
                requirements.
              </p>
              <p>
                When using the Portal, you agree not to decompile, reverse
                engineer, unmask, decrypt, create derivative works, modify, copy
                or reconstruct any data accessible through the Sandbox.
              </p>
              <p>
                You agree that if Arkea decides to subcontract parts of the
                Portal, including the Sandbox or the API to a third party, you
                will be bound towards this third party in the same way that you
                are bound to Arkea according to the Terms.
              </p>
              <p>
                In case of an occurrence of any events which are defined by
                French law as constituting “force majeure”, your access and use
                of the Portal may be interrupted.
              </p>
              <p>
                Any websites linked on the Portal have not been analyzed by
                Arkea; as a result Arkea denies any responsibility regarding the
                content of these websites.
              </p>

              <h2>Security</h2>
              <p>
                Unless you have been given a prior express consent by Arkea, you
                will also agree not to disclose to any third party any
                information to which you have access to through the Sandbox and
                the API’s. This restriction applies to, but is not limited to,
                the sale, distribution, the publication, the disclosure, the
                rent, the lease, the sublicense, or the marketing of such
                information.
              </p>
              <p>
                You must not use the Sandbox in any way that would harm the
                Portal’s stability, Arkea’s security or that would prevent
                others from using it. You also agree not to use the Portal in
                order to collect information about other users of the Portal.
                You also agree not to use the Sandbox in any way that would
                expose it or Arkea to malicious software or any security risks
                such as malware or denial of service attacks.
              </p>
              <p>
                Arkea reserves the right to contact you at its own discretion
                and based on the data you entered for the registration.
              </p>

              <h2>Intellectual property</h2>
              <p>
                All content on the Portal is protected by intellectual property
                law. Unless expressly mentioned otherwise, Arkea is the owner of
                all rights, titles, interests, intellectual property in the
                Portal, including the Sandbox, the APIs and its applications.
              </p>
              <p>
                Once registered, you are granted a temporary, limited,
                revocable, non-transferable, non-exclusive and non sublicensable
                license.
              </p>
              <p>
                This license allows you to use the information, the Sandbox and
                the APIs, in order to assess Arkea’s account information and
                payment initiation APIs.
              </p>
              <p>
                You warrant that you have the intellectual property rights to
                anything you create on, or upload to, the Portal, and that it
                does not infringe on any third party’s intellectual property.
              </p>
              <p>
                Arkea disclaims any liability for what you create on, or upload
                to, the Portal.
              </p>
              <p>
                You will guarantee Arkea against any action, complaint, claim,
                or opposition from any person who invokes an intellectual or
                industrial property right. If this does happen, compensation and
                costs of any kind incurred by Arkea (including but not limited
                to attorney’s fees) as well as damages and interests against
                Arkea will be borne by you.
              </p>
              <p>
                Any application you create on the Sandbox of the Portal you will
                own the intellectual property rights off, but Arkea will be
                granted a limited, royalty-free, non-exclusive right to use such
                an application for the Portal.
              </p>
              <p>
                If Arkea creates applications which offer similar functions to
                those you created on the Portal, Arkea will not have any
                obligation towards you.
              </p>
              <p>
                By using the Portal you accept that you will have to respect all
                the intellectual property rights of Arkea, including but not
                limited to its name, brands, logos, signs, drawings or any other
                distinctive sign used by Arkea.
              </p>
              <p>
                The use of the trademark, the copyrights, the logo or any other
                intellectual property or business secret of Arkea is not
                allowed, unless an explicit consent was given by Arkea. Any use
                without express consent given by Arkea of any of those elements
                will constitute an infringement of Arkea’s intellectual property
                and expose you to penalties.
              </p>
              <p>
                If you choose to submit information to Arkea through feedback,
                comments, or suggestions on the Portal, you will be granting
                your Intellectual Property rights to Arkea in order to improve
                the Portal and its related services.
              </p>

              <h2>Privacy</h2>
              <p>
                Information on how Arkea handles your private data is located on
                the Portal’s privacy policy page which is located{' '}
                <CustomButton to="/privacy">here</CustomButton>.
              </p>

              <h2>Future changes</h2>
              <p>
                Arkea may change the content of these Terms, or API specific
                terms, at any time.
              </p>
              <p>Any change made to them will be published on the Portal.</p>
              <p>
                By using the Portal and the APIs after the Terms have been
                changed, your continued use of the Portal will be considered as
                an acceptance of those changes.
              </p>
              <p>
                The contents of the Portal may also be changed at any time by
                Arkea and may affect your applications created on the Sandbox.
                Arkea denies any responsibility regarding any loss or damage
                resulting from this.
              </p>
              <p>
                If any provision of these Terms were to become invalid, the
                validity of the Terms as a whole will remain unaffected.
              </p>

              <h2>Access and use of the Portal</h2>
              <p>
                If you breach the Terms, your registration will be automatically
                revoked.
              </p>
              <p>
                Arkea may, at any time, decide to suspend your access to the
                Portal without prior notice for justified reasons.
              </p>
              <p>
                Arkea will not be liable for any damages or compensation
                incurred in connection with such suspensions or revocations.
              </p>
              <p>
                Arkea will not be liable, directly or indirectly in relation to
                the use of the software or the specifications released in the
                Sandbox.
              </p>
              <p>
                If Arkea does not immediately act on a breach of the Terms, this
                will not mean that Arkea waivers any rights to do so in the
                future.
              </p>
              <p>
                You are liable for all damages resulting directly or indirectly
                from actions, omissions, faults, errors and failures caused by
                you or your subcontractors when using the APIs and the Sandbox
                through the Portal.
              </p>
              <p>
                You agree to indemnify Arkea in case of any loss, damage, cost,
                or expensive, including legal expenses, incurred by Arkea
                connected to your use of the Portal, including any breach of the
                Terms.
              </p>
              <p>
                If you receive any confidential information by using the Portal,
                you agree not to disclose this information to any third party.
                Will be considered as confidential information any information
                that would be treated as confidential by any reasonable third
                party, such as, but not limited to, any information that has
                been tagged as confidential.
              </p>

              <h2>Applicable law and competent jurisdictions</h2>
              <p>
                These Terms are governed by French law. Any conflict regarding
                these Terms will be brought before the competent jurisdictions
                of Brest.
              </p>

              <h2>Miscellaneous</h2>
              <p>
                You will agree that the Portal, including the APIs and the
                Sandbox, may be subject to an audit conducted by the national
                competent authorities. This audit may include any or all of the
                data that you have entered to register on the Portal, any
                applications you created or any data you uploaded to the Portal,
                its Sandbox or its APIs.
              </p>
            </Container>
          </Section>
        )
      }
    </LanguageContext.Consumer>
  )
}
