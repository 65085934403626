import React from "react";

// components
import PageTitle from "../../components/PageTitle/PageTitle";

export default function MyAppsPage(props) {

  return (
    <>
      <PageTitle title="My Apps" />
    </>
  );  
}
