import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  widget: {
    height: "300px",
    position: "relative",
    boxShadow: theme.customShadows.widget,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
  },
  right: {
    margin: 'auto',
    height: "auto",
    position: "absolute",
    right: theme.spacing(3),
    top: theme.spacing(3),
  },
  imgRight: {
    margin: 'auto',
    marginTop: theme.spacing(5),
  },
  left: {
    margin: 'auto',
    height: "auto",
    position: "absolute",
    left: theme.spacing(3),
    top: theme.spacing(3),
  },
  imgLeft: {
    margin: 'auto',
    marginTop: theme.spacing(5),
  },
  description: {
    height: "auto",
    marginTop: theme.spacing(5),
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
    whiteSpace: "pre-wrap",
  },
}));