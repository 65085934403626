import React from "react";
import { Grid } from "@material-ui/core"
// import axios from 'axios'


// utils
// import { SDBX_OBPERSONS } from 'utils/constants'

// components

import { RightLayout, LeftLayout, RightLayoutImg } from "./components/Layout/Layout";

// Images
import sandbox from "../../images/sandbox.png";

export default function SandboxPage(props) {

  const sandboxTestingDescription = "In this section, you can find information on testing in sandbox, as well as how to get test data. "
                                    +"The Sandbox is a special environment designed just for testing, and an application that targets the Sandbox can perform all the operations it can in the Production environment."
                                    +" However, all users, items, and payment involved in Sandbox transactions are fictitious. To ensure a good user experience, we recommend you to test your solution thoroughly before launching.";
  const testingAPIDescription1 = "We supply a testing API, that simulates user actions in the app. With the API you can do the following:\n"
                                  +"- Accept and reject agreement\n"
                                  +"- Cancel agreement\n"
                                  +"- Reject subscriptions payment\n"
                                  +"- Accept and reject OneOff payment";
  const testingAPIDescription2 = "You must subscribe to the testing API, before you can use it. When calling the testing API, you must always supply an authenticated user id. You can find a list of user ids below in the section about test data.";
/*
  const [datas, setDatas] = useState([]);
  useEffect(() => {
    fetchSandbox().then(response => {
      let datas = response.data
      datas = datas.map(x => x)
      setDatas(datas)
    })
  }, []);

  async function fetchSandbox() {
    return axios.get(SDBX_OBPERSONS)
  }
*/
  // if(datas.length != 0){
  //   var legal = [];
  //   var physical = [];
  //   datas.map(x => {
  //     if(x.type === "legal") legal.push(x);
  //     if(x.type === "physical") physical.push(x);
  //   });
   
   return( 
      <>
        {/* <Table title="Legal Persons" data={legal} /> */}
        {/* <Table title="Physical Persons" data={physical} /> */}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <RightLayoutImg title="Sandbox" description={sandboxTestingDescription} img={sandbox}></RightLayoutImg>
          </Grid>
          <Grid item xs={12}>
            <LeftLayout title="Testing API" description={testingAPIDescription1} description2={testingAPIDescription2}></LeftLayout>
          </Grid>
          <Grid item xs={12}>
            <RightLayout title="Sandbox Testing"></RightLayout>
          </Grid>
          <Grid item xs={12}>
            <LeftLayout title="Difference between sandbox and production"></LeftLayout>
          </Grid>
        </Grid>
      </>
    );
  // } else {
  //   return (
  //       <>
  //         <PageTitle title="SandBox" />
  //       </>
  //     );
  // } 
}
