import React, { useState } from 'react'
import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
} from '@material-ui/core'
import {
  Menu as MenuIcon,
  // MailOutline as MailIcon,
  NotificationsNone as NotificationsIcon,
  Apps as AppsIcon,
  Person as AccountIcon,
  Search as SearchIcon,
  ArrowBack as ArrowBackIcon,
  Home as HomeIcon
} from '@material-ui/icons'
import classNames from 'classnames'

// styles
import useStyles from './styles'

// components
import { Badge, Typography } from '../Wrappers/Wrappers'
import Notification from '../Notification/Notification'

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar
} from '../../context/LayoutContext'
//import { useUserDispatch } from '../../context/UserContext'

import { useApolloClient } from '@apollo/react-hooks';


// Images
import ArkeaDeveloper from '../../images/ArkeaDeveloper.png'

const notifications = [
  { id: 0, color: 'warning', message: 'Check out this awesome ticket' },
  {
    id: 1,
    color: 'success',
    type: 'info',
    message: 'What is the best way to get ...'
  },
  {
    id: 2,
    color: 'secondary',
    type: 'notification',
    message: 'This is just a simple notification'
  },
  {
    id: 3,
    color: 'tertiary',
    type: 'e-commerce',
    message: '12 new orders has arrived today'
  }
]

function logout(client){
  localStorage.removeItem('token');
  client.writeData({ data: { isLoggedIn: false } });
}

export default function Header(props) {
  var classes = useStyles()
  const client = useApolloClient();

  // global
  var layoutState = useLayoutState()
  var layoutDispatch = useLayoutDispatch()
  //var userDispatch = useUserDispatch()

  // local
  var [notificationsMenu, setNotificationsMenu] = useState(null)
  var [isNotificationsUnread, setIsNotificationsUnread] = useState(true)
  var [profileMenu, setProfileMenu] = useState(null)
  var [isSearchOpen, setSearchOpen] = useState(false)

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(classes.headerIcon, classes.headerIconCollapse)
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(classes.headerIcon, classes.headerIconCollapse)
              }}
            />
          )}
        </IconButton>
        <Typography variant="h6" weight="medium" className={classes.logotype}>
          <img src={ArkeaDeveloper} alt="ArkeaDeveloper"/>
        </Typography>
        <div className={classes.grow} />
        <div
          className={classNames(classes.search, {
            [classes.searchFocused]: isSearchOpen
          })}
        >
          <div
            className={classNames(classes.searchIcon, {
              [classes.searchIconOpened]: isSearchOpen
            })}
            onClick={() => setSearchOpen(!isSearchOpen)}
          >
            <SearchIcon classes={{ root: classes.headerIcon }} />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
          />
        </div>
        <IconButton
          color="inherit"
          className={classes.headerMenuButton}
          onClick={() => goHome()}
        >
          <HomeIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
        <IconButton
          color="inherit"
          aria-haspopup="true"
          aria-controls="mail-menu"
          onClick={e => {
            setNotificationsMenu(e.currentTarget)
            setIsNotificationsUnread(false)
          }}
          className={classes.headerMenuButton}
        >
          <Badge
            badgeContent={isNotificationsUnread ? notifications.length : null}
            color="warning"
          >
            <NotificationsIcon classes={{ root: classes.headerIcon }} />
          </Badge>
        </IconButton>
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
        <Menu
          id="notifications-menu"
          open={Boolean(notificationsMenu)}
          anchorEl={notificationsMenu}
          onClose={() => setNotificationsMenu(null)}
          className={classes.headerMenu}
          disableAutoFocusItem
        >
          {notifications.map(notification => (
            <MenuItem
              key={notification.id}
              onClick={() => setNotificationsMenu(null)}
              className={classes.headerMenuItem}
            >
              <Notification {...notification} typographyVariant="inherit" />
            </MenuItem>
          ))}
        </Menu>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              John Smith
            </Typography>
          </div>
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem
            )}
          >
            <AccountIcon className={classes.profileMenuIcon} /> Edit Profile
          </MenuItem>
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem
            )}
          >
            <AppsIcon className={classes.profileMenuIcon} />
            My Apps
          </MenuItem>
          <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color="secondary"
              onClick={() => logout(client)}
            >
              Sign Out
            </Typography>
          </div>
        </Menu>
      </Toolbar>
    </AppBar>
  )
}

function goHome() {
  window.location.href = '/showcase#home';
}
