import React from "react";
import { Grid } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import { LanguageContext } from 'context/languageContext'
import styled from 'styled-components'

// styles
import useStyles from "./styles";


// Images
import API from "../../../../images/API.png";

export default function ApiLayout(props) {
  var classes = useStyles();

  const { product } = props

  return (
    <LanguageContext.Consumer>
      {value => (
        <>
          <Grid item xs={4}>
            <div className={classes.Api}>
                <Grid container spacing={2}>
                <Grid item><img className={classes.img} src={API} alt="API" /></Grid>
                  <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                      <Grid item xs>
                        <StyledApiStatusSpanCard>{product.status}</StyledApiStatusSpanCard>
                        <StyledApiTitleCard>{product.descriptions[value].title}</StyledApiTitleCard>
                        <StyledApiSummaryCard>{product.descriptions[value].short}</StyledApiSummaryCard>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Rating className={classes.rating}
                name="rate_{props.title}"
                value={props.rating}
                readOnly
                precision={0.5}
              />
              </div>
            </Grid>
          </>
        )}
      </LanguageContext.Consumer>
      )
}

const StyledApiStatusSpanCard = styled.span`
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 20px;
  background: ${props => props.theme.color.primary};
  color: ${props => props.theme.color.white.regular};
  width: 230px;
  text-align: center;
  text-transform: uppercase;
  -webkit-transform: translate(69px, 22px) rotate(40deg);
  -ms-transform: translate(69px, 22px) rotate(40deg);
  transform: translate(69px, 22px) rotate(40deg);
`

const StyledApiTitleCard = styled.h5`
  margin-top: 0;
  line-height: 1.2;
  color: #636267;
`

const StyledApiSummaryCard = styled.p`
  color: #636267;
  margin: 24px 0 10px 0;
  height: 128px;
  font-size: 18px;
  text-align: left;
`