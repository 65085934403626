import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { ApolloProvider } from '@apollo/react-hooks';
import { resolvers, typeDefs } from './resolvers';

const cache = new InMemoryCache();
const client = new ApolloClient({
    cache,
    link: new HttpLink({
      uri: process.env.REACT_APP_API_BACKEND_GRAPHQL,
      headers: {
        authorization: localStorage.getItem('token'),
      },
    }),
    typeDefs,
    resolvers,
  });
  
  cache.writeData({
    data: {
      isLoggedIn: !!localStorage.getItem('token')
    }
});

ReactDOM.render(
  <ApolloProvider client={client}>
      <App />
  </ApolloProvider>,

  document.getElementById('root')
)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
