import React from "react";
import { Grid } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import Translate from 'locales/Translate'

// styles
import useStyles from "./styles";

// components
import { Typography } from "../../../../components/Wrappers/Wrappers";
import DetailDialog from "../Detail/Detail";

// Images
import API from "../../../../images/API.png";

  

export default function ApiLayout(props) {
  var classes = useStyles();

  return (
    <Grid item xs={4}>
      <div className={classes.Api}>
          <Grid container spacing={2}>
            <Grid item><img className={classes.img} src={API} alt="API" /></Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography size="md" weight="medium" color="tertiary"><Translate string={props.title} /></Typography>
                  <Typography weight="light" color="tertiary">{props.channel}</Typography>
                  <Typography><Translate string={props.description}/></Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <DetailDialog name="Details" img={API} title={props.title} tag={props.channel} description={props.description}/>
          <Rating className={classes.rating}
          name="rate_{props.title}"
          value={props.rating}
          readOnly
          precision={0.5}
        />
        </div>
      </Grid>
  )
}