import React from 'react'
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import { Grid } from "@material-ui/core";

//components
import Widget from '../../../components/Widget/Widget'
import { Typography } from "../../../components/Wrappers";

export default function PricingCard(props) {

  const dummyDescription = "<ul><li>the financial goals of the company (i.e. profitability)</li> <li>the fit with marketplace realities (will customers buy at that price?)</li> <li>the extent to which the price supports a product's market positioning and be consistent with the other variables in the marketing mix</li> <li>the consistency of prices across categories and products (consistency indicates reliability and supports customer confidence and customer satisfaction)</li> <li>To meet or prevent competition</li></ul>";
    var pricingObj = {
    type: 'Basic',
    shortDescription: "Pricing is the process whereby a business sets the price at which it will sell its products and services" ,
    description: dummyDescription,
    price: '0'
  };
  try{
    const pricingData = props.pricingData[0];
    if (Object.keys(pricingObj).toString() === Object.keys(pricingData).toString()) {
     pricingObj = pricingData;
    }
  }
  catch(error) {
    console.log("no pricing table");
    // expected output: ReferenceError: nonExistentFunction is not defined
    // Note - error messages will vary depending on browser
  }


  return (
    <div>
       <Widget>
      <Grid
      >
       <div>
       <Grid container justify="center">
       <Grid item>
          <Typography color="tertiary" variant="h1">
            {pricingObj.type}
          </Typography>
        </Grid>
       </Grid>


        <Grid container justify="center" alignItems="flex-end">
          <Grid item>
            <Typography variant="h1">&nbsp;&nbsp;{pricingObj.price}</Typography>
          </Grid>
          <Grid item>
            <Typography color="tertiary">
              € / month
            </Typography>
          </Grid>
        </Grid>

        <Grid container justify="center">
          <Typography color="tertiary" >
          <br /> {pricingObj.shortDescription}
          </Typography>
        </Grid>
        </div>
      </Grid>
      <br /> <Divider />
      <br />
      <div>
      <Grid container justify="center" spacing={2}>
        <Grid item>
          
          <Typography gutterBottom color="tertiary">
          <div  dangerouslySetInnerHTML={{__html:pricingObj.description}} />
          </Typography>
        </Grid>
        <Grid item>
          <Chip label="Choose this plan" />
        </Grid>
      </Grid>
      </div>
    </Widget>
    </div>
  )
}
