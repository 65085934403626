import { RedocStandalone } from 'redoc'
import React, { useState } from 'react'
import swagger from 'swaggersDsp2/paymentinitiation.json'

export default function PaymentInitiationSwaggerRedocDsp2() {
  const [isFrActive] = useState(true)

  if (isFrActive) {
    return (
      <RedocStandalone
        specUrl={swagger}
        options={{
          hideDownloadButton: true,
          pathInMiddlePanel: true,
          lazyRendering: true,
          theme: {
            colors: { primary: { main: '#ED1C24' } },
            typography: { fontSize: '14px' },
            rightPanel: { backgroundColor: '#d4d4d4', textColor: '#F6F6F6' },
            codeSample: { backgroundColor: '#13191c' },
            menu: { backgroundColor: '#FFFFFF' }
          }
        }}
      />
    )
  }
}
