import React, {useContext, useEffect, useState} from 'react'
import {RedocStandalone} from 'redoc'

import './technicalDoc.css'
import axios from "axios";
import {LanguageContext} from "context/languageContext";
import * as merger from "swaggermergernode";

export default function TechnicalDoc(props) {

  const lang = useContext(LanguageContext);
  const [swagger, setSwagger] = useState();
  const [isLoading, setIsLoading] = useState(false);
  let swaggerMap;

  /**
   * Hook
   */
  useEffect(() => {
    setIsLoading(true);
    axios.get(process.env.REACT_APP_API_PRODUCTS).then(
        response => {
          mergeAllSwagger(response.data);
        }
    );

    setIsLoading(false);
    // eslint-disable-next-line
  }, Object.values(lang))

  /**
   * HTML component
   */
  if (isLoading || swagger === undefined) {
    return <></>
  } else {
    return (
        <RedocStandalone
            specUrl={swagger}
            options={{
              hideDownloadButton: true,
              pathInMiddlePanel: true,
              menuToggle: true,
              theme: {
                colors: {primary: {main: 'red'}},
                rightPanel: {backgroundColor: '#263238'}
              }
            }}
        />
    )
  }

  /**
   * Merge all swagger from a product list
   *
   * @param products product list
   * @returns {Promise<void>}
   */
  async function mergeAllSwagger(products) {

    // - Occurs on product list
    let promises = [];
    for (let index = 0; index < products.length; index++) {

      let product = products[index];

      for (let j = 0; j < product.descriptions.length; j++) {

        // - Only get swagger for current lang
        if (lang === product.descriptions[j].language) {

          // - Hack - swagger KO for now
          if (!product.descriptions[j].swagger.includes('transfer')
              && !product.descriptions[j].swagger.includes('budgetinsight')
              && !product.descriptions[j].swagger.includes('authorizations')) {

            // - Get swagger from stoplight
            promises.push(getSwaggerAndConcat(product.id,
                product.descriptions[j].swagger));
          }
        }
      }
    }

    // - Wait until all swagger has been downloaded
    await Promise.all(promises);

    // - Merge an array of swagger to a single one
    let host = 'example.com';
    let basepath = '/';
    let version = '1.0.0';
    let title = 'Swagger';
    let description = '';
    const info = {
      version,
      title,
      description
    };
    let schemes = ['https', 'http'];

    let mapSorted = new Map([...swaggerMap.entries()].sort());
    let swaggerArray = [...mapSorted.values()];
    let swaggerMerged = merger.merge(swaggerArray, info, basepath, host,
        schemes);
        console.log(JSON.stringify(swaggerMerged))

    setSwagger(swaggerMerged);
  }

  /**
   * Get swagger from stoplight and concat
   *
   * @param uri
   * @returns {Promise<void>}
   */
  async function getSwaggerAndConcat(id, uri) {

    await axios
    .get(uri, {
      headers: {
        Authorization: 1234
      }
    })
    .then(response => {
      if (swaggerMap) {
        // swaggerMap = swaggerMap.concat(response.data);
        // swaggerMap = [].concat(response.data);
        swaggerMap.set(id, response.data);
      } else {
        swaggerMap = new Map();
      }
    })
    .catch(error => console.error('error on getSwaggerUrl : ' + error))
  }
}
