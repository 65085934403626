import React from "react";

// styles
import { Grid } from "@material-ui/core";
// components
import { Typography } from "../../components/Wrappers";
import PageTitle from "../../components/PageTitle/PageTitle";
import PricingCard from "./components/PricingCard";

export default function PricingPage(props) {

  return (
    <>
     
      <Grid container direction="column"
             alignItems="center">
        <Grid item>
        <PageTitle title="Choose a plan" />
        </Grid>
     <Grid item>
     <Typography color="tertiary" variant="h6">
     We've got options to fit your needs now and in the future
      </Typography>
     </Grid>
      
      </Grid>
      <br/>
      <Grid container  justify="center"  spacing={2}>
      <Grid item xs={3} >
        <PricingCard ></PricingCard>

    </Grid>
    </Grid>
           

    </>
  );  
}
