import theme from 'styled-theming'

const colors = {
  grey400: '#bdbdbd',
  grey700: '#616161',
  grey800: '#424242',
  grey: '#303030',
  white: '#ffffff',
  cyan500: '#00bcd4',
  pinkA200: '#ff4081',
  red500: '#f44336',
  amber500: '#ffc107',
  green500: '#4caf50',
  orange500: '#ff9800',
  lime500: '#cddc39',
  arkeaRed: '#ED1D23',
  arkeaGray: '#F2F2F2'
}

export const themes = {
  font: {
    primary: `'arimo', sans-serif`,
    secondary: `'Average', serif`
  },
  font_size: {
    redoc: 'font-size: 14px; line-height: 26px',
    header: 'font-size: 16px; line-height: 36px',
    footer: 'font-size: 16px; line-height: 60px',
    small: 'font-size: 20px; line-height: 30px',
    regular: 'font-size: 24px; line-height: 32px',
    large: 'font-size: 30px; line-height: 40px',
    larger: 'font-size: 36px; line-height: 48px',
    xlarge: 'font-size: 48px; line-height: 56px'
  },
  color: {
    white: {
      regular: '#FFFFFF',
      dark: '#F6F6F6',
      darker: '#b1b1b1'
    },
    black: {
      lighter: '#6B6B6B',
      light: '#6B6B6B',
      regular: '#6B6B6B'
    },
    palette: {
      backgroundColor: colors.grey,
      borderColor: colors.grey700,
      textColor: colors.white,
      textInvertColor: colors.grey,
      canvasColor: colors.grey,
      primaryColor: colors.cyan500,
      accentColor: colors.pinkA200,
      errorColor: colors.red500,
      warnColor: colors.amber500,
      warnSecondaryColor: colors.orange500,
      successColor: colors.green500,
      successSecondaryColor: colors.lime500,
      disabledColor: colors.grey400,
      arkeaRed: colors.arkeaRed,
      cardBackground: colors.arkeaGray
    },
    primary: '#ED1D23'
  },
  screen: {
    xs: '800px',
    sm: '800px',
    md: '800px',
    lg: '1199px'
  }
}

export const primaryColor = theme('mode', {
  light: '#af6666',
  dark: '#ED1D23'
})

export const primaryNavBackgroundColor = theme('mode', {
  light: '#F6F6F6',
  dark: '#292c36'
})

export const primaryBackgroundColor = theme('mode', {
  light: '#FFFFFF',
  dark: '#292c36'
})

export const primaryTextFontColor = theme('mode', {
  light: '#6B6B6B',
  dark: '#ffffff'
})

export const primaryHeaderFontColor = theme('mode', {
  light: 'black',
  dark: '#ffffff'
})

export const primaryFontInversedColor = theme('mode', {
  light: '#ffffff',
  dark: '#6B6B6B'
})

export const StatusAvailableColor = theme('mode', {
  light: 'rgb(107, 189, 91)',
  dark: 'rgb(107, 189, 91)'
})

export const StatusUpcomingColor = theme('mode', {
  light: '#af6666',
  dark: '#ED1D23'
})
