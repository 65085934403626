import React from 'react'
import CustomButton from 'common/CustomButton/CustomButton'
import { LanguageContext } from 'context/languageContext'
import { Col, Card, Modal } from 'react-bootstrap'
import styled from 'styled-components'
import Translate from 'locales/Translate'
import Constants from 'utils/constants'
import { StatusAvailableColor, StatusUpcomingColor } from 'styles/theme'

import book from 'static/fontawesome/solid/book.svg'

import './catalogueCardWrapper.css'

function VerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <img
            className="modal-image doGraySvg"
            src={props.productlogo}
            alt="modal product"
          ></img>{' '}
          {props.summary}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{props.short}</h4>
        <MarginP>{props.description}</MarginP>
        <center>
          <CustomButton to={'/swagger/' + props.productid}>
            <Translate string={'modal.gotoproduct'} />
          </CustomButton>
        </center>
      </Modal.Body>
    </Modal>
  )
}

export default function CatalogueCardWrapper(props) {
  const [modalShow, setModalShow] = React.useState(false)

  const { product, backgroundColor } = props

  return (
    <LanguageContext.Consumer>
      {value => (

        <>
 {                 console.log("value" + value)}

          <Col md="6" sm="12" lg="6" xs="12" className="col" key={product.id}>
            <StyledCard backgroundColor={backgroundColor}>
              <StyledApiCatalogueCard>
                
                {product.status.toLowerCase() === 'available' ? (
                  <StyledApiAvailableStatusSpanCard>
                    <Translate string={'card.available'} />
                  </StyledApiAvailableStatusSpanCard>
                ) : product.status.toLowerCase() === 'upcoming' ? (
                  <StyledApiUpcomingStatusSpanCard>
                    <Translate string={'card.upcoming'} />
                  </StyledApiUpcomingStatusSpanCard>
                ) : (
                  <StyledApiUpcomingStatusSpanCard>
                    <Translate string={'card.sandbox'} />
                  </StyledApiUpcomingStatusSpanCard>
                )}

                <StyledApiTitleCard>
                  {product.descriptions[value].title}
                </StyledApiTitleCard>
                <StyledApiSummaryCard>
                  {product.descriptions[value].short}
                </StyledApiSummaryCard>
                <img
                  className="catalogue-image doGraySvg"
                  src={book}
                  alt={'image-catalogue-' + product.id}
                />
                <div>
                  <CustomButton onClick={() => setModalShow(true)}>
                    <Translate string={'card.moreinfo'} />
                  </CustomButton>
                  <CustomButton
                    to={'/swagger/' + product.id}
                    propsToState={{
                      swaggerUrlFr: product.descriptions[Constants.LANGUAGE_FR].swagger,
                      swaggerUrlEn: product.descriptions[Constants.LANGUAGE_EN].swagger
                    }}
                  >
                    <Translate string={'card.documentation'} />
                  </CustomButton>
                </div>
              </StyledApiCatalogueCard>
            </StyledCard>
          </Col>

          <VerticallyCenteredModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            description={product.descriptions[value].full}
            summary={product.descriptions[value].short}
            productid={product.id}
            productlogo={book}
          />
        </>
      )}
    </LanguageContext.Consumer>
  )
}

const StyledApiAvailableStatusSpanCard = styled.span`
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 20px;
  background: ${StatusAvailableColor};
  color: ${props => props.theme.color.white.regular};
  width: 230px;
  text-align: center;
  text-transform: uppercase;
  -webkit-transform: translate(69px, 22px) rotate(40deg);
  -ms-transform: translate(69px, 22px) rotate(40deg);
  transform: translate(69px, 22px) rotate(40deg);
`

const StyledApiUpcomingStatusSpanCard = styled.span`
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 20px;
  background: ${StatusUpcomingColor};
  color: ${props => props.theme.color.white.regular};
  width: 230px;
  text-align: center;
  text-transform: uppercase;
  -webkit-transform: translate(69px, 22px) rotate(40deg);
  -ms-transform: translate(69px, 22px) rotate(40deg);
  transform: translate(69px, 22px) rotate(40deg);
`

const StyledApiTitleCard = styled.h5`
  margin-top: 0;
  line-height: 1.2;
  color: #636267;
`

const StyledApiSummaryCard = styled.p`
  color: #636267;
  margin: 24px 0 10px 0;
  height: 128px;
  font-size: 18px;
  text-align: left;
`

const StyledApiCatalogueCard = styled(Card.Body)`
  position: relative;
  overflow: hidden;
`

const StyledCard = styled(Card)`
  background: ${props =>
    props.backgroundColor
      ? props.backgroundColor
      : props.theme.color.palette.cardBackground};
  border-radius: 3;
  border: 0;
`

const MarginP = styled.p`
  margin-bottom: 55px;
`
