import React from 'react';
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Translate from 'locales/Translate'

// styles
import useStyles from "./styles";

// Components
import { Typography } from "../../../../components/Wrappers/Wrappers";

// icons
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';


export default function DetailDialog(props) {
  var classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button className={classes.root} variant="outlined" color="secondary" onClick={handleClickOpen}>
        {props.name}
      </Button>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth="100%" fullHeight="100%">
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Grid container spacing={2}>
            <Grid item><img className={classes.img} src={props.img} alt="logo" /></Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography size="md" weight="medium" color="tertiary"><Translate string={props.title} /></Typography>
                  <Typography weight="light" color="tertiary">{props.tag}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Button variant="contained" color="tertiary" className={classes.edit} startIcon={<EditIcon />}>Modifier l'abonnement</Button>
          <Button variant="contained" color="secondary" className={classes.delete} startIcon={<DeleteIcon />}>Supprimer</Button>
        </DialogTitle>
        <DialogContent dividers>
          <Typography><Translate string={props.description}/></Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
}