import React from "react";
import { useTheme } from "@material-ui/styles";
import ApexCharts from "react-apexcharts";
  


function generateDayWiseTimeSeries(baseval, count, yrange) {
    var i = 0;
    var series = [];
    while (i < count) {
      var x = baseval;
      var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

      series.push([x, y]);
      baseval += 86400000;
      i++;
    }
    return series;
  }

  const  series = [{name: 'Mean Response Time', data:  generateDayWiseTimeSeries(new Date().getTime() - 3.456e10, 400, {min: 160, max: 220 }) }]


    export default function MetricsChart() {
        var theme = useTheme();
      
        return (
          <ApexCharts
            options={themeOptions(theme)}
            series={series}
            type="area"
            height={350}
          />
        );
      }

      function themeOptions(theme) {
        return {
            chart: {
              stacked: false,
              zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true
              },
              toolbar: {
                autoSelected: 'zoom'
              }
            },
            plotOptions: {
              line: {
                width: 1,
                curve: 'smooth',
              }
            },
            stroke: {
              show: true,
              curve: 'smooth',
              colors: undefined,
              width: 2,    
          },
            dataLabels: {
              enabled: false
            },

            markers: {
              size: 0,
              style: 'full',
            },
            colors: [theme.palette.secondary.light],
            title: {
              text: 'Mean Response Time',
              align: 'left'
            },
            fill: {
              type: 'gradient',
              gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 90, 100]
              },
            },
            yaxis: {
              title: {
                text: 'Response Time (ms)',
                tickAmount: 2
              },
            },
            xaxis: {
              type: 'datetime',
            },

   
          }}

      
     
  