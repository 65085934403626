/* eslint-disable import/first */

import React, {useState} from 'react'
import Scrollspy from 'react-scrollspy'

import {HashLink as Link} from 'react-router-hash-link'
import Translate from 'locales/Translate'


const logo = '/dynamic/logo.png'
import HomeIcon from 'static/icons/home.svg'
import MenuOpenIcon from 'static/fontawesome/solid/times.svg'
import MenuClosedIcon from 'static/fontawesome/solid/bars.svg'

import './navbar.css'

import {IS_LOGGED_IN} from '.././../../App'
import {useQuery} from '@apollo/react-hooks';


import {
    Nav,
    NavItem,
    Brand,
    NavListWrapper,
    MobileMenu,
    Mobile,
    Burger
} from './navbar.style'

const tabs = [
    {
        path: '/showcase#home',
        title: 'nav.home',
        id: 'home',
        isScrollspy: true,
        isAuthNeeded: false
    },
    {
        path: '/devportal/home',
        title: 'nav.devportal',
        id: 'devportal',
        isScrollspy: true,
        isAuthNeeded: true
    },
    // { path: "/showcase#contact", title: "nav.contact", id: "contact", isScrollspy: true, isAuthNeeded: false },
    {
        path: '/docs',
        title: 'nav.docs',
        id: 'catalogue',
        isScrollspy: true,
        isAuthNeeded: false
    }
]



export default function Navbar(props, {component: MatchedPage, ...rest}) {

    const {data} = useQuery(IS_LOGGED_IN);
    const [mobileMenuOpen, setMenuMobileOpen] = useState(false)

    function toggleMobileMenu() {
        setMenuMobileOpen(!mobileMenuOpen)
    }

    function closeMobileMenu() {
        if (mobileMenuOpen) {
            setMenuMobileOpen(false)
        }
    }

    function getNavList({mobile = false}) {
        return (
            <NavListWrapper mobile={mobile}>
                <Scrollspy
                    items={tabs.filter(tab => tab.isScrollspy).map(tab => tab.id)}
                    currentClassName="active"
                    mobile={mobile}
                >
                    {tabs.map((tab, index) =>
                        index === 0 ? (
                            <NavItem key={index} onClick={closeMobileMenu}>
                                <Link to={tab.path}>
                                    <img
                                        src={HomeIcon}
                                        alt="home"
                                        style={{
                                            width: '20px',
                                            margin: '15px'
                                        }}
                                    />
                                </Link>
                            </NavItem>
                        ) : (
                            <NavItem key={index} onClick={closeMobileMenu}>
                                {((tab.isAuthNeeded &&
                                    data.isLoggedIn) ||
                                    !tab.isAuthNeeded) && (
                                    <Link to={tab.path}>
                                        <Translate string={tab.title}/>
                                    </Link>
                                )}
                            </NavItem>
                        )
                    )}

                </Scrollspy>

            </NavListWrapper>
        )
    }

    return (
        <Nav {...props}>
            <div className="menu-wrap">
                <Brand>
                    <Link to="/showcase#home">
                        <img alt="Arkea" src={logo}/>
                    </Link>
                </Brand>
                <center>
                    <Mobile hide className="nav-item">
                        {getNavList({})}
                    </Mobile>
                </center>
                <Burger>
                    <button onClick={toggleMobileMenu} style={{color: 'black'}}>
                        {mobileMenuOpen ? (
                            <img
                                id="logoMenuOpen"
                                className="doGraySvg"
                                alt="Menu"
                                src={MenuOpenIcon}
                            />
                        ) : (
                            <img
                                id="logoMenuClose"
                                className="doGraySvg"
                                alt="Menu"
                                src={MenuClosedIcon}
                            />
                        )}
                    </button>
                </Burger>
            </div>
            <Mobile>
                {mobileMenuOpen && (
                    <MobileMenu>{getNavList({mobile: true})}</MobileMenu>
                )}
            </Mobile>
        </Nav>
    )
}
