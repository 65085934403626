import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withRouter } from 'react-router'

function CustomButton(props) {
  const {
    history,
    location,
    match,
    staticContext,
    to,
    onClick,
    propsToState,
    inverse,
    ...rest
  } = props

  const className = `${props.className} ${inverse ? 'btn-inverse' : ''}`
  return (
    <>
      {to === undefined ? (
        <ButtonStyled
          {...rest}
          onClick={event => {
            onClick && onClick(event)
          }}
          className={className}
        />
      ) : (
        <ButtonStyled
          {...rest}
          onClick={event => {
            onClick && onClick(event)
            history.push(to, propsToState)
          }}
          className={className}
        />
      )}
    </>
  )
}

CustomButton.propTypes = {
  propsToState: PropTypes.object,
  children: PropTypes.node.isRequired
}

export default withRouter(CustomButton)

const ButtonStyled = styled.button`
  background: white;
  color: ${props => props.theme.color.primary};
  border: 2px solid ${props => props.theme.color.primary};
  -webkit-border-radius: 43px;
  -moz-border-radius: 43px;
  border-radius: 43px;
  padding: 13px 45px;
  font-weight: 550;
  text-transform: uppercase;
  margin-right: 25px;
  margin-bottom: 25px;

  &:hover {
    cursor: pointer;
    background: ${props => props.theme.color.primary};
    color: white;
    border: 2px solid ${props => props.theme.color.primary};
    text-decoration: none;
    transition: background-color 250ms;
  }

  &.fixedRed {
    background: ${props => props.theme.color.palette.arkeaRed};
    color: white;
  }

  &.lnl {
    display: inline-block;
  }

  &.t2 {
    border: 2px solid white;
  }

  &.btn-inverse {
    background: ${props => props.theme.color.primary};
    color: white;
    border: 2px solid ${props => props.theme.color.primary};
  }

  &.btn-inverse:hover {
    color: ${props => props.theme.color.primary};
    border: 2px solid ${props => props.theme.color.primary};
    background: white;
    transition: background-color 250ms;
  }
`
