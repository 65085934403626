import React from "react";
import { useTheme } from "@material-ui/styles";
import ApexCharts from "react-apexcharts";

const hours = ["00h","01h", "02h",  "03h",  "04h", "05h", "06h",  "07h", "08h","09h", "10h",  "11h", 
"12h",  "13h", "14h","15h",  "16h", "17h", "18h",  "19h", "20h", "21h",  "22h",  "23h" ].reverse(); 

const series= Array.from({length: 24}, (x) =>    x= {
  name: "Metric1",
  data: generateData(7, {
    min: 0,
    max: 90,
  }),
},

);
series.forEach(function(element, indexVal) {
  element.name = hours[indexVal]});

export default function ApexHeatMapChart() {
  var theme = useTheme();

  return (
    <ApexCharts
      options={themeOptions(theme)}
      series={series}
      type="heatmap"
      height={350}
    />
  );
}

// ##################################################################
function generateData(count, yrange) {
  var i = 0;
  var series = [];
  var daysInWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  //daysIndex = { 'Sun': 0, 'Mon': 1, 'Tue': 2, 'Wed': 3, 'Thu': 4, 'Fri': 5, 'Sat': 6 };
  while (i < count) {
    var x = daysInWeek[i];
    var y =
      Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

    series.push({
      x: x,
      y: y,
    });
    i++;
  }

  return series;
}

function themeOptions(theme) {
  return {
    chart: {
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: [theme.palette.secondary.light],
  };
}
