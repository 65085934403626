import React from 'react'
import styled from 'styled-components'
import Translate from 'locales/Translate'
import Button from 'react-bootstrap/Button'
import { Row, Col } from 'react-bootstrap'
import { Section, Container } from 'components/global'
import { HashLink as Link } from 'react-router-hash-link'
import ImagePageNotFound from '../../../static/logos/404.png'

export default function PageNotFound() {
  return (
    <StyledSectionPageNotFound>
      <Container>
        <Row>
          <Col>
            <StyledDivPageNotFound>
              <StyledImgPageNotFound alt="ABS" src={ImagePageNotFound} />
              <br />
              <StyledParagraphPageNotFound>
                <Translate string={'pagenotfound.erreur'} />
              </StyledParagraphPageNotFound>
              <Link to="/#header">
                <ButtonGoHome variant="danger" label="Return au site">
                  <Translate string={'auth.button.return'} />
                </ButtonGoHome>
              </Link>
            </StyledDivPageNotFound>
          </Col>
        </Row>
      </Container>
    </StyledSectionPageNotFound>
  )
}

const StyledDivPageNotFound = styled.div`
  width: 100%;
`

const StyledImgPageNotFound = styled.img`
  width: 50%;
  margin-top: 10%;
  margin-left: auto;
  margin-right: auto;
  display: block;
`

const StyledParagraphPageNotFound = styled.p`
  text-align: center;
  margin-bottom: 15px;
`

const StyledSectionPageNotFound = styled(Section)`
  & + footer {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
`

const ButtonGoHome = styled(Button)`
  background-color: #ed1c24;
  color: #ffffff;
  border: none;
  margin: auto;
  display: block;

  &:hover {
    color: #ffffff;
    border: none;
  }

  &:active {
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }
`
