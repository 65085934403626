import React from "react";
import ApexCharts from "react-apexcharts";
import { useTheme } from "@material-ui/styles";

// generate data 
//var randomnumber = Math.floor(Math.random() * (maximum - minimum + 1)) + minimum; ** *** For generate number in range [min, max]
// more on https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
const series= Array.from({length: 1}, () => Math.floor(16*Math.random() ) + 85);


export default function ApexBarChart() {
  var theme = useTheme();

  return (
    <ApexCharts
      options={themeOptions(theme)}
      series={series}
      type="radialBar"
      height={650}
    />
  );
}

// ############################################################
function themeOptions(theme) {
  return {
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: '#333',
          startAngle: -90,
          endAngle: 90,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            fontSize: "20px",
            show: true
          }
        }
      }
    },
    fill: {
      colors: [theme.palette.secondary.light],
    },
    colors: [theme.palette.secondary.main],
    chart: {
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
  };
}
