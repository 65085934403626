import { RedocStandalone } from 'redoc'
import React, { useState, useEffect, useContext } from 'react'
import { LanguageContext } from 'context/languageContext'
import axios from 'axios'
import Constants from 'utils/constants'
import './RedocWrapper.css'

export default function RedocWrapper(props) {
  const ctx = useContext(LanguageContext)
  const [product, setProduct] = useState()
  const [isLoading, setIsLoading] = useState(false)

  async function fetchProduct(swaggerUrl) {
    if (!isLoading) {
      setIsLoading(true)
    }

    await axios
      .get(swaggerUrl, {
        headers: {
          Authorization: 1234
        }
      })
      .then(response => {
        setProduct(response.data)
      })
      .catch(error => console.error('error on fetchProduct' + error))
    setIsLoading(false)
  }

  async function getSwaggerUrlThenFetchProduct() {
    setIsLoading(true)
    await axios
      .get(process.env.REACT_APP_API_PRODUCTS + props.match.params.productId)
      .then(response => {
        let product = response.data

        // On transforme descriptions, on remet les language en index de tableau

        const descriptionsByLanguage = product.descriptions.reduce(
          (memo, { language, ...message }) => {
            memo[language] = message
            return memo
          },
          {}
        )

        product = {
          ...product,
          descriptions: descriptionsByLanguage
        }

        fetchProduct(
          ctx === Constants.LANGUAGE_FR
            ? product.descriptions[Constants.LANGUAGE_EN].swagger
            : product.descriptions[Constants.LANGUAGE_FR].swagger
        )
      })
      .catch(error => console.error('error on getSwaggerUrl : ' + error))
  }

  useEffect(() => {
    if (props.location.state !== undefined) {
      if (
        props.location.state.swaggerUrlEn !== undefined &&
        props.location.state.swaggerUrlFr !== undefined
      ) {
        fetchProduct(
          ctx === Constants.LANGUAGE_FR
            ? props.location.state.swaggerUrlFr
            : props.location.state.swaggerUrlEn
        )
      }
    } else {
      getSwaggerUrlThenFetchProduct()
    }
    // eslint-disable-next-line
  }, Object.values(ctx))

  if (isLoading || product === undefined) {
    return <></>
  } else {
    return (
      <RedocStandalone
        specUrl={product}
        options={{
          hideDownloadButton: true,
          pathInMiddlePanel: true,
          theme: {
            colors: { primary: { main: 'red' } },
            rightPanel: { backgroundColor: '#263238' }
          }
        }}
      />
    )
  }
}
