import React from "react";
import { Grid } from "@material-ui/core";
import { Rating } from "@material-ui/lab";

// styles
import useStyles from "./styles";

// components
import { Typography } from "../../../../components/Wrappers/Wrappers";
import DetailDialog from "../Detail/Detail";

export default function BankingLayout(props) {
  var classes = useStyles();

  return (
    <Grid item xs={4}>
      <div className={classes.Banking}>
          <Grid container spacing={2}>
            <Grid item><img className={classes.img} src={props.image} alt="Banking" /></Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography size="md" weight="medium" color="tertiary">{props.title}</Typography>
                  <Typography weight="light" color="tertiary">{props.bank}</Typography>
                  <Typography>{props.description}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <DetailDialog name="Details" img={props.image} title={props.title} tag={props.bank} description={props.description}/>
          <Rating className={classes.rating}
          name="rate_{props.title}"
          value={props.rating}
          readOnly
          precision={0.5}
        />
        </div>
      </Grid>
  )
}