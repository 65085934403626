import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  Banking:{
    boxShadow: theme.customShadows.widget,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    height: 200,
    position: "relative",
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    marginBottom: 20,
  },
  rating: {
    position: "absolute",
    right: theme.spacing(1),
    bottom: theme.spacing(1),
  },
}));
