import React from "react";
import { Grid } from "@material-ui/core";
import Widget from "../../components/Widget/Widget";
// components
import { Typography } from "../../components/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import MetricsChart from "./components/metricsChart"

const APIsList = [{apiName:'Transfer API', status :'operational', color :"success"}, 
                  {apiName:'Account API', status :'partial outage',color :"warning"},
                  {apiName:'Person API', status :'operational',color :"success"} ];


export default function SystemStatus() {
      
  return (
    <>
    <Grid container spacing={2} justify="center">
        
        {APIsList.map((value, index) => {
        return <Grid item xs={8} ><Widget> <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              spacing={2}
              
            >
              <Grid item>
                <Typography color="tertiary"  variant="h5" >
                  {value.apiName}
                </Typography>
              </Grid>
              <Grid item ><Grid  container direction="row" alignItems="center" justify="space-between">
              <Typography  color={value.color}>
                 {value.status} &nbsp;
                </Typography>
                <Dot size="large"   color={value.color} />
                
                </Grid>
              </Grid>
             </Grid>
             </Widget>
           </Grid>
          
      
      })}
        
        </Grid>

        <Grid container spacing={2} justify="space-evenly">
        <Grid item xs={8}  ></Grid>
        <Grid item xs={8}  >
        <Typography variant="h2" color="tertiary">
        Scheduled Maintenance
        </Typography>
              </Grid>
        </Grid>
        <Grid container spacing={2} justify="center">
       <Grid item xs={8}>
           <Widget title="Scheduled - Network maintenance">
           { (new Date()).toLocaleDateString()} at { (new Date()).toLocaleTimeString()} <br/>
           Scheduled - We will perform network maintenance. Brief customer service interruption is expected from this maintenance. 
         
           </Widget>
           </Grid>
      
        </Grid>

        <Grid container spacing={2} justify="space-evenly">
        <Grid item xs={8}  ></Grid>
        <Grid item xs={8}  >
        <Typography variant="h2" color="tertiary">
        Metrics
        </Typography>
              </Grid>
        </Grid>
        <Grid container spacing={2} justify="center">
       <Grid item xs={8}>
           <MetricsChart></MetricsChart>
           </Grid>
      
        </Grid>
     

        <Grid container spacing={2} justify="space-evenly">
        <Grid item xs={8}  ></Grid>
        <Grid item xs={8}  >
        <Typography variant="h2" color="tertiary">
        Incidents
        </Typography>
              </Grid>
        </Grid>
        <Grid container spacing={2} justify="center">
        <Grid item xs={8}>
           <Widget title="Monitoring - DNS resolution issues">
           
           <Typography  color="tertiary">  
           <b>Monitoring</b> - We are observing normal traffic volume and will continue to monitor. Some users may still be affected while DNS changes propagate.<br/>
           { (new Date()).toLocaleDateString()} at { (new Date()).toLocaleTimeString()} <br/>
           <b>Monitoring</b>- We have migrated to an unaffected DNS provider. Some users may experience problems with cached results as the change propagates.<br/>
           { (new Date()).toLocaleDateString()} at { (new Date()).toLocaleTimeString()} <br/>
            <b>Investigating</b> - We are investigating DNS resolution issues.<br/>
            { (new Date()).toLocaleDateString()} at { (new Date()).toLocaleTimeString()} <br/>
           </Typography>
        
         
           </Widget>
           </Grid>
      
        </Grid>
     
     
    </>
  );
}
